import { ApiUrl, getMetadata } from '../index'
import { CollectionAgency } from './collection_agency_pb'
import {
  GetCollectionAgencyRequest,
  ListCollectionAgenciesRequest,
  ApplyCollectionAgencyRequest,
  DeleteCollectionAgencyRequest
} from './collection_agencies_pb'
import { CollectionAgenciesClient } from './collection_agencies_grpc_web_pb'
import wrappers from 'google-protobuf/google/protobuf/wrappers_pb'

let client = new CollectionAgenciesClient(ApiUrl, null, null);

const toCollectionAgencyModel = (instance) => {
  let result = new CollectionAgency()
  result.setName(instance.name)
  result.setDisplayName(instance.displayName)
  result.setIsActive(instance.isActive)
  if (instance.logoUrl) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.logoUrl)
    result.setLogoUrl(wrapped)
  }
  return result
}

const API = {
  listCollectionAgencies: (params) => {
    const request = new ListCollectionAgenciesRequest()
    if (params?.page) {
      request.setPage(params.page)
    }
    if (params?.perPage) {
      request.setPerPage(params.perPage)
    }
    if (params?.sort) {
      request.setSort(params.sort)
    }
    if (params?.sortColumn) {
      request.setSortColumn(params.sortColumn)
    }
    if (params?.search) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.search)
      request.setSearch(wrapped)
    }
    return new Promise((resolve, reject) => {
      client.listCollectionAgencies(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  getCollectionAgency: (name) => {
    const request = new GetCollectionAgencyRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.getCollectionAgency(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  applyCollectionAgency: (instance) => {
    const applying = toCollectionAgencyModel(instance)
    const request = new ApplyCollectionAgencyRequest();
    request.setCollectionAgency(applying)
    return new Promise((resolve, reject) => {
      client.applyCollectionAgency(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      })
    })
  },
  deleteCollectionAgency: (name) => {
    const request = new DeleteCollectionAgencyRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.deleteCollectionAgency(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  }
};

export default API;
