// ** React Imports
import { lazy } from 'react'

const CollectionDebtorsList = lazy(() => import('views/collections/debtors/list'))
const CollectionAgenciesList = lazy(() => import('views/collections/collection-agencies/list'))
const CollectionExportsList = lazy(() => import('views/collections/collection-exports/list'))

const CollectionsRoutes = [
  {
    element: <CollectionDebtorsList />,
    path: '/debtors',
    meta: {
      resource: 'debtors',
      action: 'read'
    }
  },
  {
    element: <CollectionExportsList />,
    path: '/collectionExports',
    meta: {
      resource: 'collectionExports',
      action: 'read'
    }
  },
  {
    element: <CollectionAgenciesList />,
    path: '/collectionAgencies',
    meta: {
      resource: 'collectionAgencies',
      action: 'read'
    }
  }
]

export default CollectionsRoutes
