// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import API from 'api/invoices'

export const list = createAsyncThunk('appInvoicePayees/list', async () => {
  return await API.listInvoicePayees()
})

export const get = createAsyncThunk('appInvoicePayees/get', async name => {
  return await API.getInvoicePayee(name)
})

export const add = createAsyncThunk('appInvoicePayees/add', async (instance, { dispatch }) => {
  const updated = await API.createInvoicePayee(instance)
  instance = {
    ...instance,
    updated
  }
  await dispatch(list())
  return instance
})

export const modify = createAsyncThunk('appInvoicePayees/modify', async (instance, { dispatch }) => {
  const updated = await API.updateInvoicePayee(instance)
  instance = {
    ...instance,
    updated
  }
  await dispatch(appInvoicePayeesSlice.actions.setSelected(instance))
  await dispatch(list())
})

export const del = createAsyncThunk('appInvoicePayees/del', async (name, { dispatch }) => {
  await API.deleteInvoicePayee(name)
  await dispatch(list())
  return name
})

export const appInvoicePayeesSlice = createSlice({
  name: 'appInvoicePayees',
  initialState: {
    data: [],
    selected: null
  },
  reducers: {
    setSelected: (state, { payload }) => {
      state.selected = payload
    }
  },  extraReducers: builder => {
    builder
      .addCase(list.fulfilled, (state, action) => {
        state.data = action.payload
      })
      .addCase(get.fulfilled, (state, { payload }) => {
        state.selected = payload
      })
  }
})

export default appInvoicePayeesSlice.reducer
