/**
 * @fileoverview gRPC-Web generated client stub for dating.erp.api.v1.services
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: projects/erp/service/api/v1/services/payments.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var projects_erp_service_api_v1_model_payment_pb = require('./payment_pb.js')

var projects_erp_service_api_v1_model_money_pb = require('../money_pb.js')
const proto = {};
proto.dating = {};
proto.dating.erp = {};
proto.dating.erp.api = {};
proto.dating.erp.api.v1 = {};
proto.dating.erp.api.v1.services = require('./payments_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dating.erp.api.v1.services.PaymentsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dating.erp.api.v1.services.PaymentsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.ListPaymentsRequest,
 *   !proto.dating.erp.api.v1.services.ListPaymentsResponse>}
 */
const methodDescriptor_Payments_ListPayments = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Payments/ListPayments',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.ListPaymentsRequest,
  proto.dating.erp.api.v1.services.ListPaymentsResponse,
  /**
   * @param {!proto.dating.erp.api.v1.services.ListPaymentsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dating.erp.api.v1.services.ListPaymentsResponse.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.ListPaymentsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.services.ListPaymentsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.services.ListPaymentsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.PaymentsClient.prototype.listPayments =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Payments/ListPayments',
      request,
      metadata || {},
      methodDescriptor_Payments_ListPayments,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.ListPaymentsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.services.ListPaymentsResponse>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.PaymentsPromiseClient.prototype.listPayments =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Payments/ListPayments',
      request,
      metadata || {},
      methodDescriptor_Payments_ListPayments);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.GetPaymentRequest,
 *   !proto.dating.erp.api.v1.model.Payment>}
 */
const methodDescriptor_Payments_GetPayment = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Payments/GetPayment',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.GetPaymentRequest,
  projects_erp_service_api_v1_model_payment_pb.Payment,
  /**
   * @param {!proto.dating.erp.api.v1.services.GetPaymentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_payment_pb.Payment.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.GetPaymentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.Payment)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.Payment>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.PaymentsClient.prototype.getPayment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Payments/GetPayment',
      request,
      metadata || {},
      methodDescriptor_Payments_GetPayment,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.GetPaymentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.Payment>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.PaymentsPromiseClient.prototype.getPayment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Payments/GetPayment',
      request,
      metadata || {},
      methodDescriptor_Payments_GetPayment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.CreatePaymentRequest,
 *   !proto.dating.erp.api.v1.model.Payment>}
 */
const methodDescriptor_Payments_CreatePayment = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Payments/CreatePayment',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.CreatePaymentRequest,
  projects_erp_service_api_v1_model_payment_pb.Payment,
  /**
   * @param {!proto.dating.erp.api.v1.services.CreatePaymentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_payment_pb.Payment.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.CreatePaymentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.Payment)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.Payment>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.PaymentsClient.prototype.createPayment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Payments/CreatePayment',
      request,
      metadata || {},
      methodDescriptor_Payments_CreatePayment,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.CreatePaymentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.Payment>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.PaymentsPromiseClient.prototype.createPayment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Payments/CreatePayment',
      request,
      metadata || {},
      methodDescriptor_Payments_CreatePayment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.UpdatePaymentRequest,
 *   !proto.dating.erp.api.v1.model.Payment>}
 */
const methodDescriptor_Payments_UpdatePayment = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Payments/UpdatePayment',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.UpdatePaymentRequest,
  projects_erp_service_api_v1_model_payment_pb.Payment,
  /**
   * @param {!proto.dating.erp.api.v1.services.UpdatePaymentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_payment_pb.Payment.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.UpdatePaymentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.Payment)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.Payment>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.PaymentsClient.prototype.updatePayment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Payments/UpdatePayment',
      request,
      metadata || {},
      methodDescriptor_Payments_UpdatePayment,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.UpdatePaymentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.Payment>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.PaymentsPromiseClient.prototype.updatePayment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Payments/UpdatePayment',
      request,
      metadata || {},
      methodDescriptor_Payments_UpdatePayment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.DeletePaymentRequest,
 *   !proto.dating.erp.api.v1.services.DeletePaymentResponse>}
 */
const methodDescriptor_Payments_DeletePayment = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Payments/DeletePayment',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.DeletePaymentRequest,
  proto.dating.erp.api.v1.services.DeletePaymentResponse,
  /**
   * @param {!proto.dating.erp.api.v1.services.DeletePaymentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dating.erp.api.v1.services.DeletePaymentResponse.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.DeletePaymentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.services.DeletePaymentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.services.DeletePaymentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.PaymentsClient.prototype.deletePayment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Payments/DeletePayment',
      request,
      metadata || {},
      methodDescriptor_Payments_DeletePayment,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.DeletePaymentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.services.DeletePaymentResponse>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.PaymentsPromiseClient.prototype.deletePayment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Payments/DeletePayment',
      request,
      metadata || {},
      methodDescriptor_Payments_DeletePayment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.ListPaymentTypesRequest,
 *   !proto.dating.erp.api.v1.services.ListPaymentTypesResponse>}
 */
const methodDescriptor_Payments_ListPaymentTypes = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Payments/ListPaymentTypes',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.ListPaymentTypesRequest,
  proto.dating.erp.api.v1.services.ListPaymentTypesResponse,
  /**
   * @param {!proto.dating.erp.api.v1.services.ListPaymentTypesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dating.erp.api.v1.services.ListPaymentTypesResponse.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.ListPaymentTypesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.services.ListPaymentTypesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.services.ListPaymentTypesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.PaymentsClient.prototype.listPaymentTypes =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Payments/ListPaymentTypes',
      request,
      metadata || {},
      methodDescriptor_Payments_ListPaymentTypes,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.ListPaymentTypesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.services.ListPaymentTypesResponse>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.PaymentsPromiseClient.prototype.listPaymentTypes =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Payments/ListPaymentTypes',
      request,
      metadata || {},
      methodDescriptor_Payments_ListPaymentTypes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.GetPaymentTypeRequest,
 *   !proto.dating.erp.api.v1.model.PaymentType>}
 */
const methodDescriptor_Payments_GetPaymentType = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Payments/GetPaymentType',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.GetPaymentTypeRequest,
  projects_erp_service_api_v1_model_payment_pb.PaymentType,
  /**
   * @param {!proto.dating.erp.api.v1.services.GetPaymentTypeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_payment_pb.PaymentType.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.GetPaymentTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.PaymentType)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.PaymentType>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.PaymentsClient.prototype.getPaymentType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Payments/GetPaymentType',
      request,
      metadata || {},
      methodDescriptor_Payments_GetPaymentType,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.GetPaymentTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.PaymentType>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.PaymentsPromiseClient.prototype.getPaymentType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Payments/GetPaymentType',
      request,
      metadata || {},
      methodDescriptor_Payments_GetPaymentType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.CreatePaymentTypeRequest,
 *   !proto.dating.erp.api.v1.model.PaymentType>}
 */
const methodDescriptor_Payments_CreatePaymentType = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Payments/CreatePaymentType',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.CreatePaymentTypeRequest,
  projects_erp_service_api_v1_model_payment_pb.PaymentType,
  /**
   * @param {!proto.dating.erp.api.v1.services.CreatePaymentTypeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_payment_pb.PaymentType.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.CreatePaymentTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.PaymentType)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.PaymentType>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.PaymentsClient.prototype.createPaymentType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Payments/CreatePaymentType',
      request,
      metadata || {},
      methodDescriptor_Payments_CreatePaymentType,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.CreatePaymentTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.PaymentType>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.PaymentsPromiseClient.prototype.createPaymentType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Payments/CreatePaymentType',
      request,
      metadata || {},
      methodDescriptor_Payments_CreatePaymentType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.UpdatePaymentTypeRequest,
 *   !proto.dating.erp.api.v1.model.PaymentType>}
 */
const methodDescriptor_Payments_UpdatePaymentType = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Payments/UpdatePaymentType',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.UpdatePaymentTypeRequest,
  projects_erp_service_api_v1_model_payment_pb.PaymentType,
  /**
   * @param {!proto.dating.erp.api.v1.services.UpdatePaymentTypeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_payment_pb.PaymentType.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.UpdatePaymentTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.PaymentType)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.PaymentType>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.PaymentsClient.prototype.updatePaymentType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Payments/UpdatePaymentType',
      request,
      metadata || {},
      methodDescriptor_Payments_UpdatePaymentType,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.UpdatePaymentTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.PaymentType>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.PaymentsPromiseClient.prototype.updatePaymentType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Payments/UpdatePaymentType',
      request,
      metadata || {},
      methodDescriptor_Payments_UpdatePaymentType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.DeletePaymentTypeRequest,
 *   !proto.dating.erp.api.v1.services.DeletePaymentTypeResponse>}
 */
const methodDescriptor_Payments_DeletePaymentType = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Payments/DeletePaymentType',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.DeletePaymentTypeRequest,
  proto.dating.erp.api.v1.services.DeletePaymentTypeResponse,
  /**
   * @param {!proto.dating.erp.api.v1.services.DeletePaymentTypeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dating.erp.api.v1.services.DeletePaymentTypeResponse.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.DeletePaymentTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.services.DeletePaymentTypeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.services.DeletePaymentTypeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.PaymentsClient.prototype.deletePaymentType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Payments/DeletePaymentType',
      request,
      metadata || {},
      methodDescriptor_Payments_DeletePaymentType,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.DeletePaymentTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.services.DeletePaymentTypeResponse>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.PaymentsPromiseClient.prototype.deletePaymentType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Payments/DeletePaymentType',
      request,
      metadata || {},
      methodDescriptor_Payments_DeletePaymentType);
};


module.exports = proto.dating.erp.api.v1.services;

