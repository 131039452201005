// source: projects/erp/service/api/v1/model/trigger_instance.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
goog.exportSymbol('proto.dating.erp.api.v1.model.TriggerInstance', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dating.erp.api.v1.model.TriggerInstance = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dating.erp.api.v1.model.TriggerInstance, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dating.erp.api.v1.model.TriggerInstance.displayName = 'proto.dating.erp.api.v1.model.TriggerInstance';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dating.erp.api.v1.model.TriggerInstance.prototype.toObject = function(opt_includeInstance) {
  return proto.dating.erp.api.v1.model.TriggerInstance.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dating.erp.api.v1.model.TriggerInstance} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dating.erp.api.v1.model.TriggerInstance.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0),
    displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    triggerName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    customerName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    triggerTypeName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    condition: jspb.Message.getFieldWithDefault(msg, 8, ""),
    duration: (f = msg.getDuration()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    parametersMap: (f = msg.getParametersMap()) ? f.toObject(includeInstance, undefined) : [],
    plannedAt: (f = msg.getPlannedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    executedAt: (f = msg.getExecutedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    executedLog: (f = msg.getExecutedLog()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dating.erp.api.v1.model.TriggerInstance}
 */
proto.dating.erp.api.v1.model.TriggerInstance.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dating.erp.api.v1.model.TriggerInstance;
  return proto.dating.erp.api.v1.model.TriggerInstance.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dating.erp.api.v1.model.TriggerInstance} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dating.erp.api.v1.model.TriggerInstance}
 */
proto.dating.erp.api.v1.model.TriggerInstance.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTriggerName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTriggerTypeName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCondition(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDuration(value);
      break;
    case 10:
      var value = msg.getParametersMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPlannedAt(value);
      break;
    case 12:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExecutedAt(value);
      break;
    case 13:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setExecutedLog(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dating.erp.api.v1.model.TriggerInstance.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dating.erp.api.v1.model.TriggerInstance.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dating.erp.api.v1.model.TriggerInstance} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dating.erp.api.v1.model.TriggerInstance.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTriggerName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCustomerName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTriggerTypeName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCondition();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDuration();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getParametersMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(10, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getPlannedAt();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getExecutedAt();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getExecutedLog();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.dating.erp.api.v1.model.TriggerInstance.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dating.erp.api.v1.model.TriggerInstance} returns this
 */
proto.dating.erp.api.v1.model.TriggerInstance.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 id = 2;
 * @return {number}
 */
proto.dating.erp.api.v1.model.TriggerInstance.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dating.erp.api.v1.model.TriggerInstance} returns this
 */
proto.dating.erp.api.v1.model.TriggerInstance.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.dating.erp.api.v1.model.TriggerInstance.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dating.erp.api.v1.model.TriggerInstance} returns this
 */
proto.dating.erp.api.v1.model.TriggerInstance.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string trigger_name = 4;
 * @return {string}
 */
proto.dating.erp.api.v1.model.TriggerInstance.prototype.getTriggerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dating.erp.api.v1.model.TriggerInstance} returns this
 */
proto.dating.erp.api.v1.model.TriggerInstance.prototype.setTriggerName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string customer_name = 5;
 * @return {string}
 */
proto.dating.erp.api.v1.model.TriggerInstance.prototype.getCustomerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.dating.erp.api.v1.model.TriggerInstance} returns this
 */
proto.dating.erp.api.v1.model.TriggerInstance.prototype.setCustomerName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string trigger_type_name = 6;
 * @return {string}
 */
proto.dating.erp.api.v1.model.TriggerInstance.prototype.getTriggerTypeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.dating.erp.api.v1.model.TriggerInstance} returns this
 */
proto.dating.erp.api.v1.model.TriggerInstance.prototype.setTriggerTypeName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string product_name = 7;
 * @return {string}
 */
proto.dating.erp.api.v1.model.TriggerInstance.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.dating.erp.api.v1.model.TriggerInstance} returns this
 */
proto.dating.erp.api.v1.model.TriggerInstance.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string condition = 8;
 * @return {string}
 */
proto.dating.erp.api.v1.model.TriggerInstance.prototype.getCondition = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.dating.erp.api.v1.model.TriggerInstance} returns this
 */
proto.dating.erp.api.v1.model.TriggerInstance.prototype.setCondition = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional google.protobuf.StringValue duration = 9;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dating.erp.api.v1.model.TriggerInstance.prototype.getDuration = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 9));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dating.erp.api.v1.model.TriggerInstance} returns this
*/
proto.dating.erp.api.v1.model.TriggerInstance.prototype.setDuration = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.TriggerInstance} returns this
 */
proto.dating.erp.api.v1.model.TriggerInstance.prototype.clearDuration = function() {
  return this.setDuration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.TriggerInstance.prototype.hasDuration = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * map<string, string> parameters = 10;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.dating.erp.api.v1.model.TriggerInstance.prototype.getParametersMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 10, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.dating.erp.api.v1.model.TriggerInstance} returns this
 */
proto.dating.erp.api.v1.model.TriggerInstance.prototype.clearParametersMap = function() {
  this.getParametersMap().clear();
  return this;};


/**
 * optional google.protobuf.Timestamp planned_at = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.dating.erp.api.v1.model.TriggerInstance.prototype.getPlannedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.dating.erp.api.v1.model.TriggerInstance} returns this
*/
proto.dating.erp.api.v1.model.TriggerInstance.prototype.setPlannedAt = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.TriggerInstance} returns this
 */
proto.dating.erp.api.v1.model.TriggerInstance.prototype.clearPlannedAt = function() {
  return this.setPlannedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.TriggerInstance.prototype.hasPlannedAt = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.Timestamp executed_at = 12;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.dating.erp.api.v1.model.TriggerInstance.prototype.getExecutedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 12));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.dating.erp.api.v1.model.TriggerInstance} returns this
*/
proto.dating.erp.api.v1.model.TriggerInstance.prototype.setExecutedAt = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.TriggerInstance} returns this
 */
proto.dating.erp.api.v1.model.TriggerInstance.prototype.clearExecutedAt = function() {
  return this.setExecutedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.TriggerInstance.prototype.hasExecutedAt = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.StringValue executed_log = 13;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dating.erp.api.v1.model.TriggerInstance.prototype.getExecutedLog = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 13));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dating.erp.api.v1.model.TriggerInstance} returns this
*/
proto.dating.erp.api.v1.model.TriggerInstance.prototype.setExecutedLog = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.TriggerInstance} returns this
 */
proto.dating.erp.api.v1.model.TriggerInstance.prototype.clearExecutedLog = function() {
  return this.setExecutedLog(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.TriggerInstance.prototype.hasExecutedLog = function() {
  return jspb.Message.getField(this, 13) != null;
};


goog.object.extend(exports, proto.dating.erp.api.v1.model);
