import { ApiUrl, getMetadata } from '../index'
import {
  ListEmailOwnersRequest
} from './email_owners_pb'
import { EmailOwnersClient } from './email_owners_grpc_web_pb'

let client = new EmailOwnersClient(ApiUrl, null, null);

const API = {
  listEmailOwners: () => {
    const request = new ListEmailOwnersRequest()
    return new Promise((resolve, reject) => {
      client.listEmailOwners(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  }
};

export default API;
