import { ApiUrl, getMetadata } from '../index'
import { Customer } from './customer_pb'
import {
  GetCustomerRequest,
  ListCustomersRequest,
  ApplyCustomerRequest,
  DeleteCustomerRequest
} from './customers_pb'
import { CustomersClient } from './customers_grpc_web_pb'
import wrappers from 'google-protobuf/google/protobuf/wrappers_pb'
import { ListOfStrings } from '../list_of_strings_pb'
import { Date } from '../date_pb'
import { mapToProto, toMap } from 'utility/utils'

let client = new CustomersClient(ApiUrl, null, null);

export const toCustomerModel = (instance) => {
  let result = new Customer()
  result.setName(instance.name)
  if (instance.firstName) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.firstName)
    result.setFirstName(wrapped)
  }
  if (instance.middleName) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.middleName)
    result.setMiddleName(wrapped)
  }
  if (instance.lastName) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.lastName)
    result.setLastName(wrapped)
  }
  if (instance.zip) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.zip)
    result.setZip(wrapped)
  }
  if (instance.countryName) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.countryName)
    result.setCountryName(wrapped)
  }
  if (instance.stateName) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.stateName)
    result.setStateName(wrapped)
  }
  if (instance.cityName) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.cityName)
    result.setCityName(wrapped)
  }
  if (instance.cityDisplayName) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.cityDisplayName)
    result.setCityDisplayName(wrapped)
  }
  if (instance.street) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.street)
    result.setStreet(wrapped)
  }
  if (instance.buildingNumber) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.buildingNumber)
    result.setBuildingNumber(wrapped)
  }
  if (instance.email) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.email)
    result.setEmail(wrapped)
  }
  if (instance.mobile) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.mobile)
    result.setMobile(wrapped)
  }
  if (instance.login) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.login)
    result.setLogin(wrapped)
  }
  if (instance.dateOfBirth) {
    const date = new Date()
    date.setYear(instance.dateOfBirth.year)
    date.setMonth(instance.dateOfBirth.month)
    date.setDay(instance.dateOfBirth.day)
    result.setDateOfBirth(date)
  }
  if (instance.sex) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.sex)
    result.setSex(wrapped)
  }
  if (instance.lookingFor) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.lookingFor)
    result.setLookingFor(wrapped)
  }
	result.setStatus(instance.status)
  result.setIsInternal(instance.isInternal)
  result.setIsModerated(!!instance.isModerated)
  result.setIsMobileConfirmed(!!instance.isMobileConfirmed)
  result.setIsEmailConfirmed(!!instance.isEmailConfirmed)
  if (instance.selfieUrl) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.selfieUrl)
    result.setSelfieUrl(wrapped)
  }
  for (const i in instance.photoUrlsMap) {
    const items = new ListOfStrings()
    items.setItemsList(instance.photoUrlsMap[i][1])
    result.getPhotoUrlsMap().set(instance.photoUrlsMap[i][0], items)
  }
  for (const i in instance.valuesMap) {
    result.getValuesMap().set(instance.valuesMap[i][0], instance.valuesMap[i][1])
  }
  result.setCategoryValuesList(instance.categoryValuesList ?? [])
  result.setLookingCategoryValuesList(instance.lookingCategoryValuesList ?? [])
  if (instance.username) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.username)
    result.setUsername(wrapped)
  }
  if (instance.description) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.description)
    result.setDescription(wrapped)
  }

  return result
}

const toCustomerObject = (instance) => {
  const result = {}
  Object.keys(instance).forEach(key => {
    if (key.endsWith('List') || key.endsWith('Map') || key === "amount" || key == "balance") {
      result[key] = instance[key]
    } else if (instance[key]?.value) {
      result[key] = instance[key]?.value
    } else {
      result[key] = instance[key]
    }
  })
  const photoUrls = toMap(result.photoUrlsMap)
  for (const key in photoUrls) {
    photoUrls[key] = photoUrls[key]?.itemsList
  }
  result.photoUrlsMap = mapToProto(photoUrls)
  return result
}

const API = {
  listCustomers: (params) => {
    const request = new ListCustomersRequest();
    if (params?.page) {
      request.setPage(params.page)
    }
    if (params?.perPage) {
      request.setPerPage(params.perPage)
    }
    if (params?.sort) {
      request.setSort(params.sort)
    }
    if (params?.sortColumn) {
      request.setSortColumn(params.sortColumn)
    }
    if (params?.search) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.search)
      request.setSearch(wrapped)
    }
    if (params?.status) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.status)
      request.setStatus(wrapped)
    }
    if (params?.isInternal !== undefined) {
      request.setIsInternal(!!params?.isInternal)
    }
    if (params?.isModerated !== undefined) {
      const wrapped = new wrappers.BoolValue()
      wrapped.setValue(!!params?.isModerated)
      request.setIsModerated(wrapped)
    }

    return new Promise((resolve, reject) => {
      client.listCustomers(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          const result = response.toObject()
          resolve({
            ...result,
            customersList: result.customersList.map(toCustomerObject)
          })
        }
      })
    })
  },
  getCustomer: (name) => {
    const request = new GetCustomerRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.getCustomer(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(toCustomerObject(response.toObject()))
        }
      });
    });
  },
  applyCustomer: (instance) => {
    const applying = toCustomerModel(instance)
    const request = new ApplyCustomerRequest()
    request.setCustomer(applying)
    return new Promise((resolve, reject) => {
      client.applyCustomer(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(toCustomerObject(response.toObject()))
        }
      })
    })
  },
  deleteCustomer: (name) => {
    const request = new DeleteCustomerRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.deleteCustomer(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  }
};

export default API;
