/**
 * @fileoverview gRPC-Web generated client stub for dating.erp.api.v1.services
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: projects/erp/service/api/v1/services/rooms.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var projects_erp_service_api_v1_model_room_pb = require('./room_pb.js')
const proto = {};
proto.dating = {};
proto.dating.erp = {};
proto.dating.erp.api = {};
proto.dating.erp.api.v1 = {};
proto.dating.erp.api.v1.services = require('./rooms_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dating.erp.api.v1.services.RoomsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dating.erp.api.v1.services.RoomsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.ListRoomsRequest,
 *   !proto.dating.erp.api.v1.services.ListRoomsResponse>}
 */
const methodDescriptor_Rooms_ListRooms = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Rooms/ListRooms',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.ListRoomsRequest,
  proto.dating.erp.api.v1.services.ListRoomsResponse,
  /**
   * @param {!proto.dating.erp.api.v1.services.ListRoomsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dating.erp.api.v1.services.ListRoomsResponse.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.ListRoomsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.services.ListRoomsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.services.ListRoomsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.RoomsClient.prototype.listRooms =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Rooms/ListRooms',
      request,
      metadata || {},
      methodDescriptor_Rooms_ListRooms,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.ListRoomsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.services.ListRoomsResponse>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.RoomsPromiseClient.prototype.listRooms =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Rooms/ListRooms',
      request,
      metadata || {},
      methodDescriptor_Rooms_ListRooms);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.GetRoomRequest,
 *   !proto.dating.erp.api.v1.model.Room>}
 */
const methodDescriptor_Rooms_GetRoom = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Rooms/GetRoom',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.GetRoomRequest,
  projects_erp_service_api_v1_model_room_pb.Room,
  /**
   * @param {!proto.dating.erp.api.v1.services.GetRoomRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_room_pb.Room.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.GetRoomRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.Room)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.Room>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.RoomsClient.prototype.getRoom =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Rooms/GetRoom',
      request,
      metadata || {},
      methodDescriptor_Rooms_GetRoom,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.GetRoomRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.Room>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.RoomsPromiseClient.prototype.getRoom =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Rooms/GetRoom',
      request,
      metadata || {},
      methodDescriptor_Rooms_GetRoom);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.ApplyRoomRequest,
 *   !proto.dating.erp.api.v1.model.Room>}
 */
const methodDescriptor_Rooms_ApplyRoom = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Rooms/ApplyRoom',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.ApplyRoomRequest,
  projects_erp_service_api_v1_model_room_pb.Room,
  /**
   * @param {!proto.dating.erp.api.v1.services.ApplyRoomRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_room_pb.Room.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.ApplyRoomRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.Room)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.Room>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.RoomsClient.prototype.applyRoom =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Rooms/ApplyRoom',
      request,
      metadata || {},
      methodDescriptor_Rooms_ApplyRoom,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.ApplyRoomRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.Room>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.RoomsPromiseClient.prototype.applyRoom =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Rooms/ApplyRoom',
      request,
      metadata || {},
      methodDescriptor_Rooms_ApplyRoom);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.DeleteRoomRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Rooms_DeleteRoom = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Rooms/DeleteRoom',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.DeleteRoomRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.dating.erp.api.v1.services.DeleteRoomRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.DeleteRoomRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.RoomsClient.prototype.deleteRoom =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Rooms/DeleteRoom',
      request,
      metadata || {},
      methodDescriptor_Rooms_DeleteRoom,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.DeleteRoomRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.RoomsPromiseClient.prototype.deleteRoom =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Rooms/DeleteRoom',
      request,
      metadata || {},
      methodDescriptor_Rooms_DeleteRoom);
};


module.exports = proto.dating.erp.api.v1.services;

