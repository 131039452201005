// ** Icons Import
import { Box, DollarSign, File } from 'react-feather'

export default [
  {
    header: 'Brands',
    resource: 'brands',
    action: 'read'
  },
  {
    id: 'products',
    title: 'Products',
    icon: <Box size={20} />,
    navLink: '/products',
    resource: 'products',
    action: 'read'
  },
  {
    id: 'subscriptions',
    title: 'Subscriptions',
    icon: <DollarSign size={20} />,
    navLink: '/subscriptions',
    resource: 'subscriptions',
    action: 'read'
  },
  {
    id: 'legals',
    title: 'Legals',
    icon: <File size={20} />,
    navLink: '/legals',
    resource: 'legals',
    action: 'read'
  }
]
