// ** Icons Import
import { User } from 'react-feather'

export default [
  {
    header: 'Member Area',
    resource: 'memberarea',
    action: 'read'
  },
  {
    id: 'profiles',
    title: 'Profiles',
    icon: <User size={20} />,
    navLink: '/profiles',
    resource: 'profiles',
    action: 'read'
  }
]
