import { ApiUrl, getMetadata } from '../index'
import { Company } from './company_pb'
import {
  GetCompanyRequest,
  ListCompaniesRequest,
  ApplyCompanyRequest,
  DeleteCompanyRequest
} from './companies_pb'
import { CompaniesClient } from './companies_grpc_web_pb'
import wrappers from 'google-protobuf/google/protobuf/wrappers_pb'

let client = new CompaniesClient(ApiUrl, null, null);

const toCompanyModel = (instance) => {
  let result = new Company()
  result.setName(instance.name)
  result.setDisplayName(instance.displayName)
  result.setVat(instance.vat)
  result.setRegistrationNumber(instance.registrationNumber)
  result.setCityName(instance.cityName)
  result.setEmail(instance.email)
  result.setPhone(instance.phone)
  result.setAddress(instance.address)
  result.setIsActive(instance.isActive)

  return result
}

const API = {
  listCompanies: (params) => {
    const request = new ListCompaniesRequest()
    if (params?.page) {
      request.setPage(params.page)
    }
    if (params?.perPage) {
      request.setPerPage(params.perPage)
    }
    if (params?.sort) {
      request.setSort(params.sort)
    }
    if (params?.sortColumn) {
      request.setSortColumn(params.sortColumn)
    }
    if (params?.search) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.search)
      request.setSearch(wrapped)
    }
    return new Promise((resolve, reject) => {
      client.listCompanies(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  getCompany: (name) => {
    const request = new GetCompanyRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.getCompany(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  applyCompany: (instance) => {
    const applying = toCompanyModel(instance)
    const request = new ApplyCompanyRequest()
    request.setCompany(applying)
    return new Promise((resolve, reject) => {
      client.applyCompany(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      })
    })
  },
  deleteCompany: (name) => {
    const request = new DeleteCompanyRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.deleteCompany(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  }
};

export default API;
