/**
 * @fileoverview gRPC-Web generated client stub for dating.erp.api.v1.services
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: projects/erp/service/api/v1/services/triggers.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var projects_erp_service_api_v1_model_trigger_pb = require('./trigger_pb.js')
const proto = {};
proto.dating = {};
proto.dating.erp = {};
proto.dating.erp.api = {};
proto.dating.erp.api.v1 = {};
proto.dating.erp.api.v1.services = require('./triggers_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dating.erp.api.v1.services.TriggersClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dating.erp.api.v1.services.TriggersPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.ListTriggersRequest,
 *   !proto.dating.erp.api.v1.services.ListTriggersResponse>}
 */
const methodDescriptor_Triggers_ListTriggers = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Triggers/ListTriggers',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.ListTriggersRequest,
  proto.dating.erp.api.v1.services.ListTriggersResponse,
  /**
   * @param {!proto.dating.erp.api.v1.services.ListTriggersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dating.erp.api.v1.services.ListTriggersResponse.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.ListTriggersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.services.ListTriggersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.services.ListTriggersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.TriggersClient.prototype.listTriggers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Triggers/ListTriggers',
      request,
      metadata || {},
      methodDescriptor_Triggers_ListTriggers,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.ListTriggersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.services.ListTriggersResponse>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.TriggersPromiseClient.prototype.listTriggers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Triggers/ListTriggers',
      request,
      metadata || {},
      methodDescriptor_Triggers_ListTriggers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.GetTriggerRequest,
 *   !proto.dating.erp.api.v1.model.Trigger>}
 */
const methodDescriptor_Triggers_GetTrigger = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Triggers/GetTrigger',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.GetTriggerRequest,
  projects_erp_service_api_v1_model_trigger_pb.Trigger,
  /**
   * @param {!proto.dating.erp.api.v1.services.GetTriggerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_trigger_pb.Trigger.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.GetTriggerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.Trigger)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.Trigger>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.TriggersClient.prototype.getTrigger =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Triggers/GetTrigger',
      request,
      metadata || {},
      methodDescriptor_Triggers_GetTrigger,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.GetTriggerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.Trigger>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.TriggersPromiseClient.prototype.getTrigger =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Triggers/GetTrigger',
      request,
      metadata || {},
      methodDescriptor_Triggers_GetTrigger);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.ApplyTriggerRequest,
 *   !proto.dating.erp.api.v1.model.Trigger>}
 */
const methodDescriptor_Triggers_ApplyTrigger = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Triggers/ApplyTrigger',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.ApplyTriggerRequest,
  projects_erp_service_api_v1_model_trigger_pb.Trigger,
  /**
   * @param {!proto.dating.erp.api.v1.services.ApplyTriggerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_trigger_pb.Trigger.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.ApplyTriggerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.Trigger)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.Trigger>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.TriggersClient.prototype.applyTrigger =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Triggers/ApplyTrigger',
      request,
      metadata || {},
      methodDescriptor_Triggers_ApplyTrigger,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.ApplyTriggerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.Trigger>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.TriggersPromiseClient.prototype.applyTrigger =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Triggers/ApplyTrigger',
      request,
      metadata || {},
      methodDescriptor_Triggers_ApplyTrigger);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.ExecuteTriggerRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Triggers_ExecuteTrigger = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Triggers/ExecuteTrigger',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.ExecuteTriggerRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.dating.erp.api.v1.services.ExecuteTriggerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.ExecuteTriggerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.TriggersClient.prototype.executeTrigger =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Triggers/ExecuteTrigger',
      request,
      metadata || {},
      methodDescriptor_Triggers_ExecuteTrigger,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.ExecuteTriggerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.TriggersPromiseClient.prototype.executeTrigger =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Triggers/ExecuteTrigger',
      request,
      metadata || {},
      methodDescriptor_Triggers_ExecuteTrigger);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.DeleteTriggerRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Triggers_DeleteTrigger = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Triggers/DeleteTrigger',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.DeleteTriggerRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.dating.erp.api.v1.services.DeleteTriggerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.DeleteTriggerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.TriggersClient.prototype.deleteTrigger =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Triggers/DeleteTrigger',
      request,
      metadata || {},
      methodDescriptor_Triggers_DeleteTrigger,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.DeleteTriggerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.TriggersPromiseClient.prototype.deleteTrigger =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Triggers/DeleteTrigger',
      request,
      metadata || {},
      methodDescriptor_Triggers_DeleteTrigger);
};


module.exports = proto.dating.erp.api.v1.services;

