// ** Icons Import
import { Mail, MessageSquare, Layers,User } from 'react-feather'

export default [
  {
    header: 'Users',
    resource: 'customers',
    action: 'read'
  },
  {
    id: 'customers',
    title: 'Customers',
    icon: <User size={20} />,
    navLink: '/customers',
    resource: 'customers',
    action: 'read'
  },
  {
    id: 'customers',
    title: 'Need Verify',
    icon: <User size={20} />,
    navLink: '/verifying/customers',
    resource: 'customers',
    action: 'read'
  },
  {
    id: 'affilates',
    title: 'Affilates',
    icon: <Layers size={20} />,
    navLink: '/affilates',
    resource: 'affilates',
    action: 'read'
  },
  {
    id: 'chat',
    title: 'Chat',
    icon: <MessageSquare size={20} />,
    navLink: '/chat'
  },
  {
    id: 'email',
    title: 'E-mails',
    icon: <Mail size={20} />,
    navLink: '/email'
  }
]
