/**
 * @fileoverview gRPC-Web generated client stub for dating.erp.api.v1.services
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: projects/erp/service/api/v1/services/products.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var projects_erp_service_api_v1_model_product_pb = require('./product_pb.js')
const proto = {};
proto.dating = {};
proto.dating.erp = {};
proto.dating.erp.api = {};
proto.dating.erp.api.v1 = {};
proto.dating.erp.api.v1.services = require('./products_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dating.erp.api.v1.services.ProductsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dating.erp.api.v1.services.ProductsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.ListProductsRequest,
 *   !proto.dating.erp.api.v1.services.ListProductsResponse>}
 */
const methodDescriptor_Products_ListProducts = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Products/ListProducts',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.ListProductsRequest,
  proto.dating.erp.api.v1.services.ListProductsResponse,
  /**
   * @param {!proto.dating.erp.api.v1.services.ListProductsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dating.erp.api.v1.services.ListProductsResponse.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.ListProductsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.services.ListProductsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.services.ListProductsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.ProductsClient.prototype.listProducts =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Products/ListProducts',
      request,
      metadata || {},
      methodDescriptor_Products_ListProducts,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.ListProductsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.services.ListProductsResponse>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.ProductsPromiseClient.prototype.listProducts =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Products/ListProducts',
      request,
      metadata || {},
      methodDescriptor_Products_ListProducts);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.GetProductRequest,
 *   !proto.dating.erp.api.v1.model.Product>}
 */
const methodDescriptor_Products_GetProduct = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Products/GetProduct',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.GetProductRequest,
  projects_erp_service_api_v1_model_product_pb.Product,
  /**
   * @param {!proto.dating.erp.api.v1.services.GetProductRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_product_pb.Product.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.GetProductRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.Product)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.Product>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.ProductsClient.prototype.getProduct =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Products/GetProduct',
      request,
      metadata || {},
      methodDescriptor_Products_GetProduct,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.GetProductRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.Product>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.ProductsPromiseClient.prototype.getProduct =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Products/GetProduct',
      request,
      metadata || {},
      methodDescriptor_Products_GetProduct);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.ApplyProductRequest,
 *   !proto.dating.erp.api.v1.model.Product>}
 */
const methodDescriptor_Products_ApplyProduct = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Products/ApplyProduct',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.ApplyProductRequest,
  projects_erp_service_api_v1_model_product_pb.Product,
  /**
   * @param {!proto.dating.erp.api.v1.services.ApplyProductRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_product_pb.Product.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.ApplyProductRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.Product)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.Product>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.ProductsClient.prototype.applyProduct =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Products/ApplyProduct',
      request,
      metadata || {},
      methodDescriptor_Products_ApplyProduct,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.ApplyProductRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.Product>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.ProductsPromiseClient.prototype.applyProduct =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Products/ApplyProduct',
      request,
      metadata || {},
      methodDescriptor_Products_ApplyProduct);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.DeleteProductRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Products_DeleteProduct = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Products/DeleteProduct',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.DeleteProductRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.dating.erp.api.v1.services.DeleteProductRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.DeleteProductRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.ProductsClient.prototype.deleteProduct =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Products/DeleteProduct',
      request,
      metadata || {},
      methodDescriptor_Products_DeleteProduct,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.DeleteProductRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.ProductsPromiseClient.prototype.deleteProduct =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Products/DeleteProduct',
      request,
      metadata || {},
      methodDescriptor_Products_DeleteProduct);
};


module.exports = proto.dating.erp.api.v1.services;

