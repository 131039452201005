// ** React Imports
import { Link } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'

// ** Custom Components
import Avatar from '@core/components/avatar'

// ** Utils
import { isUserLoggedIn } from 'utility/utils'

// ** Store & Actions
import { useDispatch } from 'react-redux'
import { handleLogout } from'redux/authentication'

// ** Third Party Components
import { Power } from 'react-feather'

// ** Reactstrap Imports
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap'

// ** Default Avatar Image
import defaultAvatar from 'assets/images/avatars/avatar-blank.png'

// ** UseJWT import to get config
import useJwt from '@core/auth/jwt/useJwt'

const UserDropdown = () => {
  // ** Store Vars
  const dispatch = useDispatch()

  // ** State
  const [userData, setUserData] = useState(null)

  //** ComponentDidMount
  useEffect(() => {
    if (isUserLoggedIn() !== null) {
      setUserData(JSON.parse(localStorage.getItem('userData')))
    }
  }, [])

  const { jwt } = useJwt({})

  //** Vars
  const userAvatar = (userData && userData.avatar) || defaultAvatar

  const logout = useCallback(() => {
    dispatch(handleLogout({config: jwt.jwtConfig}))
  }, [])

  return (
    <UncontrolledDropdown tag='li' className='dropdown-user nav-item'>
      <DropdownToggle href='/' tag='a' className='nav-link dropdown-user-link' onClick={e => e.preventDefault()}>
        <div className='user-nav d-sm-flex d-none'>
          <span className='user-name fw-bold'>{(userData && userData['fullName'])}</span>
        </div>
        <Avatar img={userAvatar} imgHeight='40' imgWidth='40' status='online' />
      </DropdownToggle>
      <DropdownMenu end>
        <DropdownItem tag={Link} to='/login' onClick={logout}>
          <Power size={14} className='me-75' />
          <span className='align-middle'>Logout</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default UserDropdown
