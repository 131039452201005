// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import API from 'api/email_message_templates'

export const list = createAsyncThunk('appEmailMessageTemplates/list', async () => {
  const response = await API.listEmailMessageTemplates()
  return response.emailMessageTemplatesList
})

export const get = createAsyncThunk('appEmailMessageTemplates/get', async name => {
  return await API.getEmailMessageTemplate(name)
})

export const add = createAsyncThunk('appEmailMessageTemplates/add', async (instance, { dispatch }) => {
  const updated = await API.applyEmailMessageTemplate(instance)
  instance = {
    ...instance,
    updated
  }
  await dispatch(list())
  return instance
})

export const modify = createAsyncThunk('appEmailMessageTemplates/modify', async (instance, { dispatch }) => {
  const updated = await API.applyEmailMessageTemplate(instance)
  instance = {
    ...instance,
    updated
  }
  await dispatch(appEmailMessageTemplatesSlice.actions.setSelected(instance))
  await dispatch(list())
})

export const del = createAsyncThunk('appEmailMessageTemplates/del', async (name, { dispatch }) => {
  await API.deleteEmailMessageTemplate(name)
  await dispatch(list())
  return name
})

export const appEmailMessageTemplatesSlice = createSlice({
  name: 'appEmailMessageTemplates',
  initialState: {
    data: [],
    selected: null
  },
  reducers: {
    setSelected: (state, { payload }) => {
      state.selected = payload
    }
  },  extraReducers: builder => {
    builder
      .addCase(list.fulfilled, (state, action) => {
        state.data = action.payload
      })
      .addCase(get.fulfilled, (state, { payload }) => {
        state.selected = payload
      })
  }
})

export default appEmailMessageTemplatesSlice.reducer
