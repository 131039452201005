// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import API from 'api/subscriptions'

export const listSubscriptions = createAsyncThunk('appSubscriptions/listSubscriptions', async (params, { signal }) => {
  const response = await API.listSubscriptions(params)
  if (signal.aborted)
    return
  return {
    params,
    data: response.subscriptionsList,
    totalRows: response.totalRows
  }
})

export const getSubscriptions = createAsyncThunk('appSubscriptions/getSubscriptions', async (_, { signal }) => {
  const response = await API.listSubscriptions()
  if (signal.aborted)
    return
  return response.subscriptionsList
})

export const getSubscription = createAsyncThunk('appSubscriptions/getSubscription', async (name, { dispatch, getState }) => {
  const loaded = (getState().subscriptions.data ?? []).filter(e => e.name === name)[0]
  if (loaded)
    await dispatch(appSubscriptions.actions.setSelected(loaded))
  return await API.getSubscription(name)
})

export const addSubscription = createAsyncThunk('appSubscriptions/addSubscription', async (instance) => {
  const updated = await API.applySubscription(instance)
  instance = {
    ...instance,
    ...updated
  }
  return instance
})

export const modifySubscription = createAsyncThunk('appSubscriptions/modifySubscription', async (instance, { dispatch, getState }) => {
  const updated = await API.applySubscription(instance)
  instance = {
    ...instance,
    ...updated
  }
  await dispatch(appSubscriptions.actions.setSelected(instance))
  await dispatch(listSubscriptions(getState().subscriptions.params))
  return instance
})

export const deleteSubscription = createAsyncThunk('appSubscriptions/deleteSubscription', async (name) => {
  await API.deleteSubscription(name)
  return name
})

export const appSubscriptions = createSlice({
  name: 'appSubscriptions',
  initialState: {
    data: [],
    dataAll: [],
    total: 1,
    params: {},
    logos: {}
  },
  reducers: {
    setSelected: (state, { payload }) => {
      state.selected = payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getSubscriptions.fulfilled, (state, { payload }) => {
        state.dataAll = payload
      })
      .addCase(listSubscriptions.fulfilled, (state, { payload }) => {
        state.data = payload.data
        state.params = payload.params
        state.total = payload.totalRows
      })
      .addCase(getSubscription.fulfilled, (state, { payload }) => {
        state.selected = payload
      })
  }
})

export default appSubscriptions.reducer
