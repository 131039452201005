// ** Navigation imports
import locations from './locations'
import customers from './customers'
import memberarea from './memberarea'
import brands from './brands'
import references from './references'
import collections from './collections'
import financial from './financial'

// ** Merge & Export
export default [...customers, ...collections, ...brands, ...memberarea, ...financial, ...references, ...locations]
