// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import API from 'api/customers'
import UsersAPI from 'api/users'
import DocumentsAPI from 'api/documents'
import CustomerLikeAPI from 'api/customer_likes'
import ChatRoomAPI from 'api/chat_rooms'
import TriggerInstanceAPI from 'api/trigger_instances'
import HistoryAPI from 'api/history_operations'
import axios from 'axios'

export const login = createAsyncThunk('appCustomers/login', async (params) => {
  return await UsersAPI.login(params)
})

export const getOneTimeAccess = createAsyncThunk('appCustomers/getOneTimeAccess', async () => {
  return await UsersAPI.getOneTimeAccess()
})

export const getAllData = createAsyncThunk('appCustomers/getAllData', async () => {
  let response = await API.listCustomers()
  return response.customersList
})

export const searchAddress = createAsyncThunk('appCustomers/searchAddress', async ({ q }) => {
  const response = await axios.get(`https://nominatim.openstreetmap.org/search?q=${q}&accept-language=en-EN&format=geocodejson&addressdetails=1&limit=1`)
  return response
})

export const listCustomers = createAsyncThunk('appCustomers/listCustomers', async (params, { signal }) => {
  const response = await API.listCustomers({
    ...params,
    isInternal: false
  })
  if (signal.aborted)
    return
  return {
    params,
    data: response.customersList,
    totalRows: response.totalRows
  }
})

export const listCustomerLikes = createAsyncThunk('appCustomers/listCustomerLikes', async (params, { signal }) => {
  const response = await CustomerLikeAPI.listCustomerLikes({
    ...params
  })
  if (signal.aborted)
    return
  return {
    params,
    data: response.customerLikesList,
    totalRows: response.totalRows
  }
})

export const listHistoryOperations = createAsyncThunk('appCustomers/listHistroyOperations', async (params, { signal }) => {
  const response = await HistoryAPI.listHistoryOperations({
    ...params
  })
  if (signal.aborted)
    return
  return {
    data: response.historyOperationsList,
    totalRows: response.totalRows
  }
})

export const listCustomerChats = createAsyncThunk('appCustomers/listCustomerChats', async (params, { signal }) => {
  const response = await ChatRoomAPI.listChatRooms({
    ...params
  })
  if (signal.aborted)
    return
  return {
    params,
    data: response.roomsList,
    totalRows: response.totalRows
  }
})

export const listTriggerInstances = createAsyncThunk('appCustomers/listTriggerInstances', async (params, { signal }) => {
  const response = await TriggerInstanceAPI.listTriggerInstances({
    ...params
  })
  if (signal.aborted)
    return
  return {
    params,
    data: response.triggerinstancesList,
    totalRows: response.totalRows
  }
})

export const listInvoices = createAsyncThunk('appCustomers/listInvoices', async () => {
  return []
})

export const getCustomer = createAsyncThunk('appCustomers/getCustomer', async (name, { dispatch, getState }) => {
  const loaded = (getState().customers.data ?? []).filter(e => e.name === name)[0]
  if (loaded)
    await dispatch(appCustomers.actions.setSelected(loaded))
  return await API.getCustomer(name)
})

export const addCustomer = createAsyncThunk('appCustomers/addCustomer', async (instance) => {
  const updated = await API.applyCustomer({
    ...instance,
    isInternal: false
  })
  instance = {
    ...instance,
    ...updated
  }
  return instance
})

export const modifyCustomer = createAsyncThunk('appCustomers/modifyCustomer', async (instance, { dispatch, getState }) => {
  const updated = await API.applyCustomer({
    ...instance,
    isInternal: false
  })
  instance = {
    ...instance,
    ...updated
  }
  await dispatch(appCustomers.actions.setSelected(instance))
  await dispatch(listCustomers(getState().customers.params))
  return instance
})

export const deleteCustomer = createAsyncThunk('appCustomers/deleteCustomer', async (name) => {
  await API.deleteCustomer(name)
  return name
})

const selfieLocation2type = location => location.replace('/selfies', '')
const selfieType2Location = type => `${type}/selfies`

export const getSelfies = createAsyncThunk('appCustomers/getSelfies', async (typeName) => {
  const response = await DocumentsAPI.listDocuments({ location: selfieType2Location(typeName) })
  return response
})

export const addSelfie = createAsyncThunk('appCustomers/addSelfie', async (document) => {
  const updated = await DocumentsAPI.applyDocument(document)
  document = {
    ...document,
    ...updated
  }
  return document
})

export const deleteSelfie = createAsyncThunk('appCustomers/deleteSelfie', async ({ name, location }, { dispatch }) => {
  await DocumentsAPI.deleteDocument(name)
  await dispatch(getSelfies(selfieLocation2type(location)))
  return name
})

export const deleteImageByUrl = createAsyncThunk('appProducts/deleteImageByUrl', async ({ location, url }) => {
  const docs = await DocumentsAPI.listDocuments({
    location
  })
  const doc = docs.filter(e => e.url === url)[0]
  console.log(docs, doc, url)
  if (doc) {
    await DocumentsAPI.deleteDocument(doc.name)
  }
})

export const appCustomers = createSlice({
  name: 'appCustomers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    images: {},
    allData: []
  },
  reducers: {
    setSelected: (state, { payload }) => {
      state.selected = payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, { payload }) => {
        state.allData = payload
      })
      .addCase(listCustomers.fulfilled, (state, { payload }) => {
        state.data = payload.data
        state.params = payload.params
        state.total = payload.totalRows
      })
      .addCase(getCustomer.fulfilled, (state, { payload }) => {
        state.selected = payload
      })
  }
})

export default appCustomers.reducer
