import { ApiUrl, getMetadata } from '../index'
import { Trigger } from './trigger_pb'
import {
  GetTriggerRequest,
  ListTriggersRequest,
  ApplyTriggerRequest,
  DeleteTriggerRequest,
  ExecuteTriggerRequest
} from './triggers_pb'
import { TriggersClient } from './triggers_grpc_web_pb'
import wrappers from 'google-protobuf/google/protobuf/wrappers_pb'

let client = new TriggersClient(ApiUrl, null, null);

const toTriggerModel = (instance) => {
  let result = new Trigger()
  result.setName(instance.name)
  result.setDisplayName(instance.displayName)
  result.setProductName(instance.productName)
  // todo

  return result
}

const API = {
  listTriggers: (params) => {
    const request = new ListTriggersRequest()
    if (params?.productName) {
      request.setProductName(params.productName)
    }
    return new Promise((resolve, reject) => {
      client.listTriggers(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  getTrigger: (name) => {
    const request = new GetTriggerRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.getTrigger(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  applyTrigger: (instance) => {
    const applying = toTriggerModel(instance)
    const request = new ApplyTriggerRequest();
    request.setTrigger(applying)
    return new Promise((resolve, reject) => {
      client.applyTrigger(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      })
    })
  },
  deleteTrigger: (name) => {
    const request = new DeleteTriggerRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.deleteTrigger(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  executeTrigger: (params) => {
    const request = new ExecuteTriggerRequest()
    request.setName(params.name)
    if (params?.mobile) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.mobile)
      request.setMobile(wrapped)
    }
    if (params?.email) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.email)
      request.setEmail(wrapped)
    }
    for (const i in params.parametersMap) {
      request.getParametersMap().set(params.parametersMap[i][0], params.parametersMap[i][1])
    }

    return new Promise((resolve, reject) => {
      client.executeTrigger(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  }
};

export default API;
