/**
 * @fileoverview gRPC-Web generated client stub for dating.erp.api.v1.services
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: projects/erp/service/api/v1/services/email_message_templates.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')

var projects_erp_service_api_v1_model_email_message_template_pb = require('./email_message_template_pb.js')
const proto = {};
proto.dating = {};
proto.dating.erp = {};
proto.dating.erp.api = {};
proto.dating.erp.api.v1 = {};
proto.dating.erp.api.v1.services = require('./email_message_templates_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dating.erp.api.v1.services.EmailMessageTemplatesClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dating.erp.api.v1.services.EmailMessageTemplatesPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.ListEmailMessageTemplatesRequest,
 *   !proto.dating.erp.api.v1.services.ListEmailMessageTemplatesResponse>}
 */
const methodDescriptor_EmailMessageTemplates_ListEmailMessageTemplates = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.EmailMessageTemplates/ListEmailMessageTemplates',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.ListEmailMessageTemplatesRequest,
  proto.dating.erp.api.v1.services.ListEmailMessageTemplatesResponse,
  /**
   * @param {!proto.dating.erp.api.v1.services.ListEmailMessageTemplatesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dating.erp.api.v1.services.ListEmailMessageTemplatesResponse.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.ListEmailMessageTemplatesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.services.ListEmailMessageTemplatesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.services.ListEmailMessageTemplatesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.EmailMessageTemplatesClient.prototype.listEmailMessageTemplates =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.EmailMessageTemplates/ListEmailMessageTemplates',
      request,
      metadata || {},
      methodDescriptor_EmailMessageTemplates_ListEmailMessageTemplates,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.ListEmailMessageTemplatesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.services.ListEmailMessageTemplatesResponse>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.EmailMessageTemplatesPromiseClient.prototype.listEmailMessageTemplates =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.EmailMessageTemplates/ListEmailMessageTemplates',
      request,
      metadata || {},
      methodDescriptor_EmailMessageTemplates_ListEmailMessageTemplates);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.GetEmailMessageTemplateRequest,
 *   !proto.dating.erp.api.v1.model.EmailMessageTemplate>}
 */
const methodDescriptor_EmailMessageTemplates_GetEmailMessageTemplate = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.EmailMessageTemplates/GetEmailMessageTemplate',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.GetEmailMessageTemplateRequest,
  projects_erp_service_api_v1_model_email_message_template_pb.EmailMessageTemplate,
  /**
   * @param {!proto.dating.erp.api.v1.services.GetEmailMessageTemplateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_email_message_template_pb.EmailMessageTemplate.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.GetEmailMessageTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.EmailMessageTemplate)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.EmailMessageTemplate>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.EmailMessageTemplatesClient.prototype.getEmailMessageTemplate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.EmailMessageTemplates/GetEmailMessageTemplate',
      request,
      metadata || {},
      methodDescriptor_EmailMessageTemplates_GetEmailMessageTemplate,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.GetEmailMessageTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.EmailMessageTemplate>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.EmailMessageTemplatesPromiseClient.prototype.getEmailMessageTemplate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.EmailMessageTemplates/GetEmailMessageTemplate',
      request,
      metadata || {},
      methodDescriptor_EmailMessageTemplates_GetEmailMessageTemplate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.ApplyEmailMessageTemplateRequest,
 *   !proto.dating.erp.api.v1.model.EmailMessageTemplate>}
 */
const methodDescriptor_EmailMessageTemplates_ApplyEmailMessageTemplate = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.EmailMessageTemplates/ApplyEmailMessageTemplate',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.ApplyEmailMessageTemplateRequest,
  projects_erp_service_api_v1_model_email_message_template_pb.EmailMessageTemplate,
  /**
   * @param {!proto.dating.erp.api.v1.services.ApplyEmailMessageTemplateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_email_message_template_pb.EmailMessageTemplate.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.ApplyEmailMessageTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.EmailMessageTemplate)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.EmailMessageTemplate>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.EmailMessageTemplatesClient.prototype.applyEmailMessageTemplate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.EmailMessageTemplates/ApplyEmailMessageTemplate',
      request,
      metadata || {},
      methodDescriptor_EmailMessageTemplates_ApplyEmailMessageTemplate,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.ApplyEmailMessageTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.EmailMessageTemplate>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.EmailMessageTemplatesPromiseClient.prototype.applyEmailMessageTemplate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.EmailMessageTemplates/ApplyEmailMessageTemplate',
      request,
      metadata || {},
      methodDescriptor_EmailMessageTemplates_ApplyEmailMessageTemplate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.DeleteEmailMessageTemplateRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_EmailMessageTemplates_DeleteEmailMessageTemplate = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.EmailMessageTemplates/DeleteEmailMessageTemplate',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.DeleteEmailMessageTemplateRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.dating.erp.api.v1.services.DeleteEmailMessageTemplateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.DeleteEmailMessageTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.EmailMessageTemplatesClient.prototype.deleteEmailMessageTemplate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.EmailMessageTemplates/DeleteEmailMessageTemplate',
      request,
      metadata || {},
      methodDescriptor_EmailMessageTemplates_DeleteEmailMessageTemplate,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.DeleteEmailMessageTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.EmailMessageTemplatesPromiseClient.prototype.deleteEmailMessageTemplate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.EmailMessageTemplates/DeleteEmailMessageTemplate',
      request,
      metadata || {},
      methodDescriptor_EmailMessageTemplates_DeleteEmailMessageTemplate);
};


module.exports = proto.dating.erp.api.v1.services;

