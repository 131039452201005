import { ApiUrl, getMetadata } from '../index'
import { Legal } from './legal_pb'
import {
  GetLegalRequest,
  ListLegalsRequest,
  ApplyLegalRequest,
  DeleteLegalRequest
} from './legals_pb'
import { LegalsClient } from './legals_grpc_web_pb'
import wrappers from 'google-protobuf/google/protobuf/wrappers_pb'

let client = new LegalsClient(ApiUrl, null, null);

const toLegalModel = (instance) => {
  let result = new Legal()
  result.setName(instance.name)
  result.setDisplayName(instance.displayName)
  result.setDescription(instance.description)
  result.setText(instance.text)

  return result
}

const API = {
  listLegals: (params) => {
    const request = new ListLegalsRequest()
    if (params?.page) {
      request.setPage(params.page)
    }
    if (params?.perPage) {
      request.setPerPage(params.perPage)
    }
    if (params?.sort) {
      request.setSort(params.sort)
    }
    if (params?.sortColumn) {
      request.setSortColumn(params.sortColumn)
    }
    if (params?.productName) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.productName)
      request.setProductName(wrapped)
    }
    return new Promise((resolve, reject) => {
      client.listLegals(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  getLegal: (name) => {
    const request = new GetLegalRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.getLegal(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  applyLegal: (instance) => {
    const applying = toLegalModel(instance)
    const request = new ApplyLegalRequest();
    request.setLegal(applying)
    return new Promise((resolve, reject) => {
      client.applyLegal(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      })
    })
  },
  deleteLegal: (name) => {
    const request = new DeleteLegalRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.deleteLegal(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  }
};

export default API;
