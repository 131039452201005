/**
 * @fileoverview gRPC-Web generated client stub for dating.erp.api.v1.services
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: projects/erp/service/api/v1/services/companies.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var projects_erp_service_api_v1_model_company_pb = require('./company_pb.js')
const proto = {};
proto.dating = {};
proto.dating.erp = {};
proto.dating.erp.api = {};
proto.dating.erp.api.v1 = {};
proto.dating.erp.api.v1.services = require('./companies_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dating.erp.api.v1.services.CompaniesClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dating.erp.api.v1.services.CompaniesPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.ListCompaniesRequest,
 *   !proto.dating.erp.api.v1.services.ListCompaniesResponse>}
 */
const methodDescriptor_Companies_ListCompanies = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Companies/ListCompanies',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.ListCompaniesRequest,
  proto.dating.erp.api.v1.services.ListCompaniesResponse,
  /**
   * @param {!proto.dating.erp.api.v1.services.ListCompaniesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dating.erp.api.v1.services.ListCompaniesResponse.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.ListCompaniesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.services.ListCompaniesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.services.ListCompaniesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.CompaniesClient.prototype.listCompanies =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Companies/ListCompanies',
      request,
      metadata || {},
      methodDescriptor_Companies_ListCompanies,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.ListCompaniesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.services.ListCompaniesResponse>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.CompaniesPromiseClient.prototype.listCompanies =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Companies/ListCompanies',
      request,
      metadata || {},
      methodDescriptor_Companies_ListCompanies);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.GetCompanyRequest,
 *   !proto.dating.erp.api.v1.model.Company>}
 */
const methodDescriptor_Companies_GetCompany = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Companies/GetCompany',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.GetCompanyRequest,
  projects_erp_service_api_v1_model_company_pb.Company,
  /**
   * @param {!proto.dating.erp.api.v1.services.GetCompanyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_company_pb.Company.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.GetCompanyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.Company)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.Company>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.CompaniesClient.prototype.getCompany =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Companies/GetCompany',
      request,
      metadata || {},
      methodDescriptor_Companies_GetCompany,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.GetCompanyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.Company>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.CompaniesPromiseClient.prototype.getCompany =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Companies/GetCompany',
      request,
      metadata || {},
      methodDescriptor_Companies_GetCompany);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.ApplyCompanyRequest,
 *   !proto.dating.erp.api.v1.model.Company>}
 */
const methodDescriptor_Companies_ApplyCompany = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Companies/ApplyCompany',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.ApplyCompanyRequest,
  projects_erp_service_api_v1_model_company_pb.Company,
  /**
   * @param {!proto.dating.erp.api.v1.services.ApplyCompanyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_company_pb.Company.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.ApplyCompanyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.Company)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.Company>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.CompaniesClient.prototype.applyCompany =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Companies/ApplyCompany',
      request,
      metadata || {},
      methodDescriptor_Companies_ApplyCompany,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.ApplyCompanyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.Company>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.CompaniesPromiseClient.prototype.applyCompany =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Companies/ApplyCompany',
      request,
      metadata || {},
      methodDescriptor_Companies_ApplyCompany);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.DeleteCompanyRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Companies_DeleteCompany = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Companies/DeleteCompany',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.DeleteCompanyRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.dating.erp.api.v1.services.DeleteCompanyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.DeleteCompanyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.CompaniesClient.prototype.deleteCompany =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Companies/DeleteCompany',
      request,
      metadata || {},
      methodDescriptor_Companies_DeleteCompany,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.DeleteCompanyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.CompaniesPromiseClient.prototype.deleteCompany =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Companies/DeleteCompany',
      request,
      metadata || {},
      methodDescriptor_Companies_DeleteCompany);
};


module.exports = proto.dating.erp.api.v1.services;

