/**
 * @fileoverview gRPC-Web generated client stub for dating.erp.api.v1.services
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: projects/erp/service/api/v1/services/invoices.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')

var projects_erp_service_api_v1_model_invoice_pb = require('./invoice_pb.js')
const proto = {};
proto.dating = {};
proto.dating.erp = {};
proto.dating.erp.api = {};
proto.dating.erp.api.v1 = {};
proto.dating.erp.api.v1.services = require('./invoices_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dating.erp.api.v1.services.InvoicesClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dating.erp.api.v1.services.InvoicesPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.ListInvoicesRequest,
 *   !proto.dating.erp.api.v1.services.ListInvoicesResponse>}
 */
const methodDescriptor_Invoices_ListInvoices = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Invoices/ListInvoices',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.ListInvoicesRequest,
  proto.dating.erp.api.v1.services.ListInvoicesResponse,
  /**
   * @param {!proto.dating.erp.api.v1.services.ListInvoicesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dating.erp.api.v1.services.ListInvoicesResponse.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.ListInvoicesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.services.ListInvoicesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.services.ListInvoicesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.InvoicesClient.prototype.listInvoices =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Invoices/ListInvoices',
      request,
      metadata || {},
      methodDescriptor_Invoices_ListInvoices,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.ListInvoicesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.services.ListInvoicesResponse>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.InvoicesPromiseClient.prototype.listInvoices =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Invoices/ListInvoices',
      request,
      metadata || {},
      methodDescriptor_Invoices_ListInvoices);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.GetInvoiceRequest,
 *   !proto.dating.erp.api.v1.model.Invoice>}
 */
const methodDescriptor_Invoices_GetInvoice = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Invoices/GetInvoice',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.GetInvoiceRequest,
  projects_erp_service_api_v1_model_invoice_pb.Invoice,
  /**
   * @param {!proto.dating.erp.api.v1.services.GetInvoiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_invoice_pb.Invoice.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.GetInvoiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.Invoice)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.Invoice>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.InvoicesClient.prototype.getInvoice =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Invoices/GetInvoice',
      request,
      metadata || {},
      methodDescriptor_Invoices_GetInvoice,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.GetInvoiceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.Invoice>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.InvoicesPromiseClient.prototype.getInvoice =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Invoices/GetInvoice',
      request,
      metadata || {},
      methodDescriptor_Invoices_GetInvoice);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.CreateInvoiceRequest,
 *   !proto.dating.erp.api.v1.model.Invoice>}
 */
const methodDescriptor_Invoices_CreateInvoice = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Invoices/CreateInvoice',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.CreateInvoiceRequest,
  projects_erp_service_api_v1_model_invoice_pb.Invoice,
  /**
   * @param {!proto.dating.erp.api.v1.services.CreateInvoiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_invoice_pb.Invoice.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.CreateInvoiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.Invoice)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.Invoice>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.InvoicesClient.prototype.createInvoice =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Invoices/CreateInvoice',
      request,
      metadata || {},
      methodDescriptor_Invoices_CreateInvoice,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.CreateInvoiceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.Invoice>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.InvoicesPromiseClient.prototype.createInvoice =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Invoices/CreateInvoice',
      request,
      metadata || {},
      methodDescriptor_Invoices_CreateInvoice);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.UpdateInvoiceRequest,
 *   !proto.dating.erp.api.v1.model.Invoice>}
 */
const methodDescriptor_Invoices_UpdateInvoice = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Invoices/UpdateInvoice',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.UpdateInvoiceRequest,
  projects_erp_service_api_v1_model_invoice_pb.Invoice,
  /**
   * @param {!proto.dating.erp.api.v1.services.UpdateInvoiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_invoice_pb.Invoice.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.UpdateInvoiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.Invoice)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.Invoice>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.InvoicesClient.prototype.updateInvoice =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Invoices/UpdateInvoice',
      request,
      metadata || {},
      methodDescriptor_Invoices_UpdateInvoice,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.UpdateInvoiceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.Invoice>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.InvoicesPromiseClient.prototype.updateInvoice =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Invoices/UpdateInvoice',
      request,
      metadata || {},
      methodDescriptor_Invoices_UpdateInvoice);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.DeleteInvoiceRequest,
 *   !proto.dating.erp.api.v1.services.DeleteInvoiceResponse>}
 */
const methodDescriptor_Invoices_DeleteInvoice = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Invoices/DeleteInvoice',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.DeleteInvoiceRequest,
  proto.dating.erp.api.v1.services.DeleteInvoiceResponse,
  /**
   * @param {!proto.dating.erp.api.v1.services.DeleteInvoiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dating.erp.api.v1.services.DeleteInvoiceResponse.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.DeleteInvoiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.services.DeleteInvoiceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.services.DeleteInvoiceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.InvoicesClient.prototype.deleteInvoice =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Invoices/DeleteInvoice',
      request,
      metadata || {},
      methodDescriptor_Invoices_DeleteInvoice,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.DeleteInvoiceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.services.DeleteInvoiceResponse>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.InvoicesPromiseClient.prototype.deleteInvoice =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Invoices/DeleteInvoice',
      request,
      metadata || {},
      methodDescriptor_Invoices_DeleteInvoice);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.ListInvoiceTypesRequest,
 *   !proto.dating.erp.api.v1.services.ListInvoiceTypesResponse>}
 */
const methodDescriptor_Invoices_ListInvoiceTypes = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Invoices/ListInvoiceTypes',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.ListInvoiceTypesRequest,
  proto.dating.erp.api.v1.services.ListInvoiceTypesResponse,
  /**
   * @param {!proto.dating.erp.api.v1.services.ListInvoiceTypesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dating.erp.api.v1.services.ListInvoiceTypesResponse.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.ListInvoiceTypesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.services.ListInvoiceTypesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.services.ListInvoiceTypesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.InvoicesClient.prototype.listInvoiceTypes =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Invoices/ListInvoiceTypes',
      request,
      metadata || {},
      methodDescriptor_Invoices_ListInvoiceTypes,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.ListInvoiceTypesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.services.ListInvoiceTypesResponse>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.InvoicesPromiseClient.prototype.listInvoiceTypes =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Invoices/ListInvoiceTypes',
      request,
      metadata || {},
      methodDescriptor_Invoices_ListInvoiceTypes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.GetInvoiceTypeRequest,
 *   !proto.dating.erp.api.v1.model.InvoiceType>}
 */
const methodDescriptor_Invoices_GetInvoiceType = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Invoices/GetInvoiceType',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.GetInvoiceTypeRequest,
  projects_erp_service_api_v1_model_invoice_pb.InvoiceType,
  /**
   * @param {!proto.dating.erp.api.v1.services.GetInvoiceTypeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_invoice_pb.InvoiceType.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.GetInvoiceTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.InvoiceType)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.InvoiceType>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.InvoicesClient.prototype.getInvoiceType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Invoices/GetInvoiceType',
      request,
      metadata || {},
      methodDescriptor_Invoices_GetInvoiceType,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.GetInvoiceTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.InvoiceType>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.InvoicesPromiseClient.prototype.getInvoiceType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Invoices/GetInvoiceType',
      request,
      metadata || {},
      methodDescriptor_Invoices_GetInvoiceType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.CreateInvoiceTypeRequest,
 *   !proto.dating.erp.api.v1.model.InvoiceType>}
 */
const methodDescriptor_Invoices_CreateInvoiceType = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Invoices/CreateInvoiceType',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.CreateInvoiceTypeRequest,
  projects_erp_service_api_v1_model_invoice_pb.InvoiceType,
  /**
   * @param {!proto.dating.erp.api.v1.services.CreateInvoiceTypeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_invoice_pb.InvoiceType.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.CreateInvoiceTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.InvoiceType)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.InvoiceType>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.InvoicesClient.prototype.createInvoiceType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Invoices/CreateInvoiceType',
      request,
      metadata || {},
      methodDescriptor_Invoices_CreateInvoiceType,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.CreateInvoiceTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.InvoiceType>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.InvoicesPromiseClient.prototype.createInvoiceType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Invoices/CreateInvoiceType',
      request,
      metadata || {},
      methodDescriptor_Invoices_CreateInvoiceType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.UpdateInvoiceTypeRequest,
 *   !proto.dating.erp.api.v1.model.InvoiceType>}
 */
const methodDescriptor_Invoices_UpdateInvoiceType = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Invoices/UpdateInvoiceType',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.UpdateInvoiceTypeRequest,
  projects_erp_service_api_v1_model_invoice_pb.InvoiceType,
  /**
   * @param {!proto.dating.erp.api.v1.services.UpdateInvoiceTypeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_invoice_pb.InvoiceType.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.UpdateInvoiceTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.InvoiceType)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.InvoiceType>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.InvoicesClient.prototype.updateInvoiceType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Invoices/UpdateInvoiceType',
      request,
      metadata || {},
      methodDescriptor_Invoices_UpdateInvoiceType,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.UpdateInvoiceTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.InvoiceType>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.InvoicesPromiseClient.prototype.updateInvoiceType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Invoices/UpdateInvoiceType',
      request,
      metadata || {},
      methodDescriptor_Invoices_UpdateInvoiceType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.DeleteInvoiceTypeRequest,
 *   !proto.dating.erp.api.v1.services.DeleteInvoiceTypeResponse>}
 */
const methodDescriptor_Invoices_DeleteInvoiceType = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Invoices/DeleteInvoiceType',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.DeleteInvoiceTypeRequest,
  proto.dating.erp.api.v1.services.DeleteInvoiceTypeResponse,
  /**
   * @param {!proto.dating.erp.api.v1.services.DeleteInvoiceTypeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dating.erp.api.v1.services.DeleteInvoiceTypeResponse.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.DeleteInvoiceTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.services.DeleteInvoiceTypeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.services.DeleteInvoiceTypeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.InvoicesClient.prototype.deleteInvoiceType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Invoices/DeleteInvoiceType',
      request,
      metadata || {},
      methodDescriptor_Invoices_DeleteInvoiceType,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.DeleteInvoiceTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.services.DeleteInvoiceTypeResponse>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.InvoicesPromiseClient.prototype.deleteInvoiceType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Invoices/DeleteInvoiceType',
      request,
      metadata || {},
      methodDescriptor_Invoices_DeleteInvoiceType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.ListInvoicePayeesRequest,
 *   !proto.dating.erp.api.v1.services.ListInvoicePayeesResponse>}
 */
const methodDescriptor_Invoices_ListInvoicePayees = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Invoices/ListInvoicePayees',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.ListInvoicePayeesRequest,
  proto.dating.erp.api.v1.services.ListInvoicePayeesResponse,
  /**
   * @param {!proto.dating.erp.api.v1.services.ListInvoicePayeesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dating.erp.api.v1.services.ListInvoicePayeesResponse.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.ListInvoicePayeesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.services.ListInvoicePayeesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.services.ListInvoicePayeesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.InvoicesClient.prototype.listInvoicePayees =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Invoices/ListInvoicePayees',
      request,
      metadata || {},
      methodDescriptor_Invoices_ListInvoicePayees,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.ListInvoicePayeesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.services.ListInvoicePayeesResponse>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.InvoicesPromiseClient.prototype.listInvoicePayees =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Invoices/ListInvoicePayees',
      request,
      metadata || {},
      methodDescriptor_Invoices_ListInvoicePayees);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.GetInvoicePayeeRequest,
 *   !proto.dating.erp.api.v1.model.InvoicePayee>}
 */
const methodDescriptor_Invoices_GetInvoicePayee = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Invoices/GetInvoicePayee',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.GetInvoicePayeeRequest,
  projects_erp_service_api_v1_model_invoice_pb.InvoicePayee,
  /**
   * @param {!proto.dating.erp.api.v1.services.GetInvoicePayeeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_invoice_pb.InvoicePayee.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.GetInvoicePayeeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.InvoicePayee)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.InvoicePayee>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.InvoicesClient.prototype.getInvoicePayee =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Invoices/GetInvoicePayee',
      request,
      metadata || {},
      methodDescriptor_Invoices_GetInvoicePayee,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.GetInvoicePayeeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.InvoicePayee>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.InvoicesPromiseClient.prototype.getInvoicePayee =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Invoices/GetInvoicePayee',
      request,
      metadata || {},
      methodDescriptor_Invoices_GetInvoicePayee);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.CreateInvoicePayeeRequest,
 *   !proto.dating.erp.api.v1.model.InvoicePayee>}
 */
const methodDescriptor_Invoices_CreateInvoicePayee = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Invoices/CreateInvoicePayee',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.CreateInvoicePayeeRequest,
  projects_erp_service_api_v1_model_invoice_pb.InvoicePayee,
  /**
   * @param {!proto.dating.erp.api.v1.services.CreateInvoicePayeeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_invoice_pb.InvoicePayee.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.CreateInvoicePayeeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.InvoicePayee)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.InvoicePayee>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.InvoicesClient.prototype.createInvoicePayee =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Invoices/CreateInvoicePayee',
      request,
      metadata || {},
      methodDescriptor_Invoices_CreateInvoicePayee,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.CreateInvoicePayeeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.InvoicePayee>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.InvoicesPromiseClient.prototype.createInvoicePayee =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Invoices/CreateInvoicePayee',
      request,
      metadata || {},
      methodDescriptor_Invoices_CreateInvoicePayee);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.UpdateInvoicePayeeRequest,
 *   !proto.dating.erp.api.v1.model.InvoicePayee>}
 */
const methodDescriptor_Invoices_UpdateInvoicePayee = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Invoices/UpdateInvoicePayee',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.UpdateInvoicePayeeRequest,
  projects_erp_service_api_v1_model_invoice_pb.InvoicePayee,
  /**
   * @param {!proto.dating.erp.api.v1.services.UpdateInvoicePayeeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_invoice_pb.InvoicePayee.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.UpdateInvoicePayeeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.InvoicePayee)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.InvoicePayee>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.InvoicesClient.prototype.updateInvoicePayee =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Invoices/UpdateInvoicePayee',
      request,
      metadata || {},
      methodDescriptor_Invoices_UpdateInvoicePayee,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.UpdateInvoicePayeeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.InvoicePayee>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.InvoicesPromiseClient.prototype.updateInvoicePayee =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Invoices/UpdateInvoicePayee',
      request,
      metadata || {},
      methodDescriptor_Invoices_UpdateInvoicePayee);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.DeleteInvoicePayeeRequest,
 *   !proto.dating.erp.api.v1.services.DeleteInvoicePayeeResponse>}
 */
const methodDescriptor_Invoices_DeleteInvoicePayee = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Invoices/DeleteInvoicePayee',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.DeleteInvoicePayeeRequest,
  proto.dating.erp.api.v1.services.DeleteInvoicePayeeResponse,
  /**
   * @param {!proto.dating.erp.api.v1.services.DeleteInvoicePayeeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dating.erp.api.v1.services.DeleteInvoicePayeeResponse.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.DeleteInvoicePayeeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.services.DeleteInvoicePayeeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.services.DeleteInvoicePayeeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.InvoicesClient.prototype.deleteInvoicePayee =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Invoices/DeleteInvoicePayee',
      request,
      metadata || {},
      methodDescriptor_Invoices_DeleteInvoicePayee,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.DeleteInvoicePayeeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.services.DeleteInvoicePayeeResponse>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.InvoicesPromiseClient.prototype.deleteInvoicePayee =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Invoices/DeleteInvoicePayee',
      request,
      metadata || {},
      methodDescriptor_Invoices_DeleteInvoicePayee);
};


module.exports = proto.dating.erp.api.v1.services;

