/**
 * @fileoverview gRPC-Web generated client stub for dating.erp.api.v1.services
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: projects/erp/service/api/v1/services/states.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

var projects_erp_service_api_v1_model_state_pb = require('./state_pb.js')
const proto = {};
proto.dating = {};
proto.dating.erp = {};
proto.dating.erp.api = {};
proto.dating.erp.api.v1 = {};
proto.dating.erp.api.v1.services = require('./states_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dating.erp.api.v1.services.StatesClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dating.erp.api.v1.services.StatesPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.ListStatesRequest,
 *   !proto.dating.erp.api.v1.services.ListStatesResponse>}
 */
const methodDescriptor_States_ListStates = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.States/ListStates',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.ListStatesRequest,
  proto.dating.erp.api.v1.services.ListStatesResponse,
  /**
   * @param {!proto.dating.erp.api.v1.services.ListStatesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dating.erp.api.v1.services.ListStatesResponse.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.ListStatesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.services.ListStatesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.services.ListStatesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.StatesClient.prototype.listStates =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.States/ListStates',
      request,
      metadata || {},
      methodDescriptor_States_ListStates,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.ListStatesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.services.ListStatesResponse>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.StatesPromiseClient.prototype.listStates =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.States/ListStates',
      request,
      metadata || {},
      methodDescriptor_States_ListStates);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.GetStateRequest,
 *   !proto.dating.erp.api.v1.model.State>}
 */
const methodDescriptor_States_GetState = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.States/GetState',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.GetStateRequest,
  projects_erp_service_api_v1_model_state_pb.State,
  /**
   * @param {!proto.dating.erp.api.v1.services.GetStateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_state_pb.State.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.GetStateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.State)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.State>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.StatesClient.prototype.getState =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.States/GetState',
      request,
      metadata || {},
      methodDescriptor_States_GetState,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.GetStateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.State>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.StatesPromiseClient.prototype.getState =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.States/GetState',
      request,
      metadata || {},
      methodDescriptor_States_GetState);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.ApplyStateRequest,
 *   !proto.dating.erp.api.v1.model.State>}
 */
const methodDescriptor_States_ApplyState = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.States/ApplyState',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.ApplyStateRequest,
  projects_erp_service_api_v1_model_state_pb.State,
  /**
   * @param {!proto.dating.erp.api.v1.services.ApplyStateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_state_pb.State.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.ApplyStateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.State)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.State>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.StatesClient.prototype.applyState =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.States/ApplyState',
      request,
      metadata || {},
      methodDescriptor_States_ApplyState,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.ApplyStateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.State>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.StatesPromiseClient.prototype.applyState =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.States/ApplyState',
      request,
      metadata || {},
      methodDescriptor_States_ApplyState);
};


module.exports = proto.dating.erp.api.v1.services;

