// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import API from 'api/customers'
import DocumentsAPI from 'api/documents'
import { getFileUrl } from 'api'

export const listCustomers = createAsyncThunk('appProfiles/listCustomers', async (params, { signal }) => {
  const response = await API.listCustomers({
    params,
    isInternal: true
  })
  if (signal.aborted)
    return
  return {
    params,
    data: response.customersList,
    totalRows: response.totalRows
  }
})

export const listLikes = createAsyncThunk('appProfiles/listInvoices', async () => {
  return []
})

export const getCustomer = createAsyncThunk('appProfiles/getCustomer', async (name, { dispatch, getState }) => {
  const loaded = (getState().profiles.data ?? []).filter(e => e.name === name)[0]
  if (loaded)
    await dispatch(appProfiles.actions.setSelected(loaded))
  return await API.getCustomer(name)
})

export const addCustomer = createAsyncThunk('appProfiles/addCustomer', async (instance) => {
  const updated = await API.applyCustomer({
    ...instance,
    isInternal: true
  })
  instance = {
    ...instance,
    ...updated
  }
  return instance
})

export const modifyCustomer = createAsyncThunk('appProfiles/modifyCustomer', async (instance, { dispatch, getState }) => {
  const updated = await API.applyCustomer({
    ...instance,
    isInternal: true
  })
  instance = {
    ...instance,
    ...updated
  }
  await dispatch(appProfiles.actions.setSelected(instance))
  await dispatch(listCustomers(getState().profiles.params))
  return instance
})

export const deleteCustomer = createAsyncThunk('appProfiles/deleteCustomer', async (name) => {
  await API.deleteCustomer(name)
  return name
})

export const getDocumentByUrl = createAsyncThunk('appProjects/getDocumentByUrl', async (url) => {
  return { url: getFileUrl(url) }
})

export const addImage = createAsyncThunk('appProjects/addImage', async (document) => {
  const updated = await DocumentsAPI.applyDocument(document)
  document = {
    ...document,
    ...updated
  }
  return document
})

export const deleteImage = createAsyncThunk('appProjects/deleteImage', async ({ name }) => {
  await DocumentsAPI.deleteDocument(name)
  return name
})

export const deleteImageByUrl = createAsyncThunk('appProjects/deleteImageByUrl', async ({ location, url }) => {
  const docs = await DocumentsAPI.listDocuments({
    location
  })
  const doc = docs.filter(e => e.url === url)[0]
  console.log(docs, doc, url)
  if (doc) {
    await DocumentsAPI.deleteDocument(doc.name)
  }
})

export const appProfiles = createSlice({
  name: 'appProfiles',
  initialState: {
    data: [],
    total: 1,
    params: {},
    images: {}
  },
  reducers: {
    setSelected: (state, { payload }) => {
      state.selected = payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(listCustomers.fulfilled, (state, { payload }) => {
        state.data = payload.data
        state.params = payload.params
        state.total = payload.totalRows
      })
      .addCase(getCustomer.fulfilled, (state, { payload }) => {
        state.selected = payload
      })
  }
})

export default appProfiles.reducer
