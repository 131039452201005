// ** React Imports
import { lazy } from 'react'

const ProfilesList = lazy(() => import('views/users/profiles/list'))
const ProfilesView = lazy(() => import('views/users/profiles/view'))
const ProfilesEdit = lazy(() => import('views/users/profiles/edit'))
const ProfilesAdd = lazy(() => import('views/users/profiles/add'))

const ProfilesRoutes = [
  {
    element: <ProfilesAdd />,
    path: '/profiles/-/add',
    meta: {
      resource: 'profiles',
      action: 'read'
    }
  },
  {
    element: <ProfilesView />,
    path: '/profiles/:id/view',
    meta: {
      resource: 'profiles',
      action: 'read'
    }
  },
  {
    element: <ProfilesEdit />,
    path: '/profiles/:id/edit',
    meta: {
      resource: 'profiles',
      action: 'read'
    }
  },
  {
    element: <ProfilesList />,
    path: '/profiles',
    meta: {
      resource: 'profiles',
      action: 'read'
    }
  }
]

export default ProfilesRoutes
