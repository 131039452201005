// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import API from 'api/legals'

export const listLegals = createAsyncThunk('appLegals/listLegals', async (params, { signal }) => {
  const response = await API.listLegals(params)
  if (signal.aborted)
    return
  return {
    params,
    data: response.legalsList,
    totalRows: response.totalRows
  }
})

export const getLegals = createAsyncThunk('appLegals/getLegals', async (_, { signal }) => {
  const response = await API.listLegals()
  if (signal.aborted)
    return
  return response.legalsList
})

export const getLegal = createAsyncThunk('appLegals/getLegal', async (name, { dispatch, getState }) => {
  const loaded = (getState().legals.data ?? []).filter(e => e.name === name)[0]
  if (loaded)
    await dispatch(appLegals.actions.setSelected(loaded))
  return await API.getLegal(name)
})

export const addLegal = createAsyncThunk('appLegals/addLegal', async (instance) => {
  const updated = await API.applyLegal(instance)
  instance = {
    ...instance,
    ...updated
  }
  return instance
})

export const modifyLegal = createAsyncThunk('appLegals/modifyLegal', async (instance, { dispatch, getState }) => {
  const updated = await API.applyLegal(instance)
  instance = {
    ...instance,
    ...updated
  }
  await dispatch(appLegals.actions.setSelected(instance))
  await dispatch(listLegals(getState().legals.params))
  return instance
})

export const deleteLegal = createAsyncThunk('appLegals/deleteLegal', async (name) => {
  await API.deleteLegal(name)
  return name
})

export const appLegals = createSlice({
  name: 'appLegals',
  initialState: {
    data: [],
    dataAll: [],
    total: 1,
    params: {},
    logos: {}
  },
  reducers: {
    setSelected: (state, { payload }) => {
      state.selected = payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getLegals.fulfilled, (state, { payload }) => {
        state.dataAll = payload
      })
      .addCase(listLegals.fulfilled, (state, { payload }) => {
        state.data = payload.data
        state.params = payload.params
        state.total = payload.totalRows
      })
      .addCase(getLegal.fulfilled, (state, { payload }) => {
        state.selected = payload
      })
  }
})

export default appLegals.reducer
