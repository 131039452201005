/**
 * @fileoverview gRPC-Web generated client stub for dating.erp.api.v1.services
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: projects/erp/service/api/v1/services/approvals.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var projects_erp_service_api_v1_model_approval_pb = require('./approval_pb.js')
const proto = {};
proto.dating = {};
proto.dating.erp = {};
proto.dating.erp.api = {};
proto.dating.erp.api.v1 = {};
proto.dating.erp.api.v1.services = require('./approvals_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dating.erp.api.v1.services.ApprovalsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dating.erp.api.v1.services.ApprovalsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.ListApprovalsRequest,
 *   !proto.dating.erp.api.v1.services.ListApprovalsResponse>}
 */
const methodDescriptor_Approvals_ListApprovals = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Approvals/ListApprovals',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.ListApprovalsRequest,
  proto.dating.erp.api.v1.services.ListApprovalsResponse,
  /**
   * @param {!proto.dating.erp.api.v1.services.ListApprovalsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dating.erp.api.v1.services.ListApprovalsResponse.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.ListApprovalsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.services.ListApprovalsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.services.ListApprovalsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.ApprovalsClient.prototype.listApprovals =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Approvals/ListApprovals',
      request,
      metadata || {},
      methodDescriptor_Approvals_ListApprovals,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.ListApprovalsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.services.ListApprovalsResponse>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.ApprovalsPromiseClient.prototype.listApprovals =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Approvals/ListApprovals',
      request,
      metadata || {},
      methodDescriptor_Approvals_ListApprovals);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.CreateApprovalRequest,
 *   !proto.dating.erp.api.v1.model.Approval>}
 */
const methodDescriptor_Approvals_CreateApproval = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Approvals/CreateApproval',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.CreateApprovalRequest,
  projects_erp_service_api_v1_model_approval_pb.Approval,
  /**
   * @param {!proto.dating.erp.api.v1.services.CreateApprovalRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_approval_pb.Approval.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.CreateApprovalRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.Approval)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.Approval>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.ApprovalsClient.prototype.createApproval =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Approvals/CreateApproval',
      request,
      metadata || {},
      methodDescriptor_Approvals_CreateApproval,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.CreateApprovalRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.Approval>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.ApprovalsPromiseClient.prototype.createApproval =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Approvals/CreateApproval',
      request,
      metadata || {},
      methodDescriptor_Approvals_CreateApproval);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.DeleteApprovalRequest,
 *   !proto.dating.erp.api.v1.services.DeleteApprovalResponse>}
 */
const methodDescriptor_Approvals_DeleteApproval = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Approvals/DeleteApproval',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.DeleteApprovalRequest,
  proto.dating.erp.api.v1.services.DeleteApprovalResponse,
  /**
   * @param {!proto.dating.erp.api.v1.services.DeleteApprovalRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dating.erp.api.v1.services.DeleteApprovalResponse.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.DeleteApprovalRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.services.DeleteApprovalResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.services.DeleteApprovalResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.ApprovalsClient.prototype.deleteApproval =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Approvals/DeleteApproval',
      request,
      metadata || {},
      methodDescriptor_Approvals_DeleteApproval,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.DeleteApprovalRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.services.DeleteApprovalResponse>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.ApprovalsPromiseClient.prototype.deleteApproval =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Approvals/DeleteApproval',
      request,
      metadata || {},
      methodDescriptor_Approvals_DeleteApproval);
};


module.exports = proto.dating.erp.api.v1.services;

