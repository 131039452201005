// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import API from 'api/affilate_systems'

export const list = createAsyncThunk('appAffilateSystems/list', async () => {
  return await API.listAffilateSystems()
})

export const get = createAsyncThunk('appAffilateSystems/get', async name => {
  return await API.getAffilateSystem(name)
})

export const add = createAsyncThunk('appAffilateSystems/add', async (instance, { dispatch }) => {
  const updated = await API.applyAffilateSystem(instance)
  instance = {
    ...instance,
    updated
  }
  await dispatch(list())
  return instance
})

export const modify = createAsyncThunk('appAffilateSystems/modify', async (instance, { dispatch }) => {
  const updated = await API.applyAffilateSystem(instance)
  instance = {
    ...instance,
    updated
  }
  await dispatch(appAffilateSystemsSlice.actions.setSelected(instance))
  await dispatch(list())
})

export const del = createAsyncThunk('appAffilateSystems/del', async (name, { dispatch }) => {
  await API.deleteAffilateSystem(name)
  await dispatch(list())
  return name
})

export const appAffilateSystemsSlice = createSlice({
  name: 'appAffilateSystems',
  initialState: {
    data: [],
    selected: null
  },
  reducers: {
    setSelected: (state, { payload }) => {
      state.selected = payload
    }
  },  extraReducers: builder => {
    builder
      .addCase(list.fulfilled, (state, action) => {
        state.data = action.payload
      })
      .addCase(get.fulfilled, (state, { payload }) => {
        state.selected = payload
      })
  }
})

export default appAffilateSystemsSlice.reducer
