import { ApiUrl, getMetadata } from '../index'
import { CustomerCategory } from './customer_category_pb'
import {
  GetCustomerCategoryRequest,
  ListCustomerCategoriesRequest,
  ApplyCustomerCategoryRequest,
  DeleteCustomerCategoryRequest
} from './customer_categories_pb'
import { CustomerCategoriesClient } from './customer_categories_grpc_web_pb'
import wrappers from 'google-protobuf/google/protobuf/wrappers_pb'

let client = new CustomerCategoriesClient(ApiUrl, null, null);

const toCustomerCategoryModel = (instance) => {
  let result = new CustomerCategory()
  result.setName(instance.name)
  result.setCode(instance.code)
  result.setDisplayName(instance.displayName)
  result.setIsActive(instance.isActive)
  if (instance.parentName) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.parentName)
    result.setParentName(wrapped)
  }
  result.setTypesList(instance.typesList ?? [])
  return result
}

const API = {
  listCustomerCategories: () => {
    const request = new ListCustomerCategoriesRequest();

    return new Promise((resolve, reject) => {
      client.listCustomerCategories(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject().customerCategoriesList)
        }
      })
    })
  },
  getCustomerCategory: (name) => {
    const request = new GetCustomerCategoryRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.getCustomerCategory(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  applyCustomerCategory: (instance) => {
    const applying = toCustomerCategoryModel(instance)
    const request = new ApplyCustomerCategoryRequest()
    request.setCustomerCategory(applying)
    return new Promise((resolve, reject) => {
      client.applyCustomerCategory(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      })
    })
  },
  deleteCustomerCategory: (name) => {
    const request = new DeleteCustomerCategoryRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.deleteCustomerCategory(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  }
};

export default API;
