// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import API from 'api/collection_exports'

export const list = createAsyncThunk('appCollectionExports/list', async () => {
  const response = await API.listCollectionExports()
  return response.collectionExportsList
})

export const get = createAsyncThunk('appCollectionExports/get', async name => {
  return await API.getCollectionExport(name)
})

export const add = createAsyncThunk('appCollectionExports/add', async (instance, { dispatch }) => {
  const updated = await API.applyCollectionExport(instance)
  instance = {
    ...instance,
    ...updated
  }
  await dispatch(list())
  return instance
})

export const modify = createAsyncThunk('appCollectionExports/modify', async (instance, { dispatch }) => {
  const updated = await API.applyCollectionExport(instance)
  instance = {
    ...instance,
    ...updated
  }
  await dispatch(appCollectionExportsSlice.actions.setSelected(instance))
  await dispatch(list())
})

export const del = createAsyncThunk('appCollectionExports/del', async (name, { dispatch }) => {
  await API.deleteCollectionExport(name)
  await dispatch(list())
  return name
})

export const appCollectionExportsSlice = createSlice({
  name: 'appCollectionExports',
  initialState: {
    data: [],
    selected: null
  },
  reducers: {
    setSelected: (state, { payload }) => {
      state.selected = payload
    }
  },  extraReducers: builder => {
    builder
      .addCase(list.fulfilled, (state, action) => {
        state.data = action.payload
      })
      .addCase(get.fulfilled, (state, { payload }) => {
        state.selected = payload
      })
  }
})

export default appCollectionExportsSlice.reducer
