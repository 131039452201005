import { ApiUrl, getMetadata } from '../index'
import { EmailMessage } from './email_message_pb'
import {
  GetEmailMessageRequest,
  ListEmailMessagesRequest,
  ApplyEmailMessageRequest,
  DeleteEmailMessageRequest,
  SendEmailMessageRequest
} from './email_messages_pb'
import { EmailMessagesClient } from './email_messages_grpc_web_pb'
import wrappers from 'google-protobuf/google/protobuf/wrappers_pb'

let client = new EmailMessagesClient(ApiUrl, null, null);

const toEmailMessageModel = (instance) => {
  let result = new EmailMessage()
  result.setName(instance.name)
  if (instance.parentName) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.parentName)
    result.setParentName(wrapped)
  }
  if (instance.customerName) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.customerName)
    result.setCustomerName(wrapped)
  }
  result.setSubject(instance.subject)
  result.setBodyHtml(instance.bodyHtml)
  result.setBodyText(instance.bodyText)
  result.setOwnerEmail(instance.ownerEmail)
  result.setSender(instance.sender)
  result.setIsRead(instance.isRead)
  if ((instance?.recipientsList ?? []).length) {
    result.setRecipientsList(instance.recipientsList)
  }
  if ((instance?.copiesList ?? []).length) {
    result.setCopiesList(instance.copies)
  }
  if ((instance?.attachmentsList ?? []).length) {
    result.setAttachmentsList(instance.attachmentsList)
  }

  return result
}

const API = {
  listEmailMessages: (params) => {
    const request = new ListEmailMessagesRequest()
    if (params?.page) {
      request.setPage(params.page)
    }
    if (params?.perPage) {
      request.setPerPage(params.perPage)
    }
    if (params?.sort) {
      request.setSort(params.sort)
    }
    if (params?.sortColumn) {
      request.setSortColumn(params.sortColumn)
    }
    if (params?.search) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.search)
      request.setSearch(wrapped)
    }
    if (params?.customerName) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.customerName)
      request.setCustomerName(wrapped)
    }
    if (params?.folderDisplayName) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.folderDisplayName)
      request.setFolderDisplayName(wrapped)
    }
    if (params?.threadIndex) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.threadIndex)
      request.setThreadIndex(wrapped)
    }
    return new Promise((resolve, reject) => {
      client.listEmailMessages(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  getEmailMessage: (name) => {
    const request = new GetEmailMessageRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.getEmailMessage(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  applyEmailMessage: (instance) => {
    const applying = toEmailMessageModel(instance)
    const request = new ApplyEmailMessageRequest()
    request.setEmailMessage(applying)
    return new Promise((resolve, reject) => {
      client.applyEmailMessage(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      })
    })
  },
  sendEmailMessage: (name) => {
    const request = new SendEmailMessageRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.sendEmailMessage(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  deleteEmailMessage: (name) => {
    const request = new DeleteEmailMessageRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.deleteEmailMessage(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  }
};

export default API;
