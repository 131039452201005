import { ApiUrl, getMetadata } from '../index'
import { Message } from './message_pb'
import {
  GetMessageRequest,
  ListMessagesRequest,
  ApplyMessageRequest,
  DeleteMessageRequest
} from './messages_pb'
import { MessagesClient } from './messages_grpc_web_pb'
import wrappers from 'google-protobuf/google/protobuf/wrappers_pb'

let client = new MessagesClient(ApiUrl, null, null);

const toMessageModel = (instance) => {
  let result = new Message()
  result.setName(instance.name)
  result.setRoomName(instance.roomName)
  result.setUserName(instance.userName)
  result.setText(instance.text)

  if (instance.replyMessageName) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.replyMessageName)
    result.setReplyMessageName(wrapped)
  }

  return result
}

const toMessageObject = (instance) => {
  const result = {}
  Object.keys(instance).forEach(key => {
    if (key.endsWith('List') || key.endsWith('Map') || key === "amount") {
      result[key] = instance[key]
    } else if (instance[key]?.value) {
      result[key] = instance[key]?.value
    } else {
      result[key] = instance[key]
    }
  })
  return result
}

const API = {
  listChatMessages: (params) => {
    const request = new ListMessagesRequest();
    if (params?.page) {
      request.setPage(params.page)
    }
    if (params?.perPage) {
      request.setPerPage(params.perPage)
    }
    if (params?.sort) {
      request.setSort(params.sort)
    }
    if (params?.sortColumn) {
      request.setSortColumn(params.sortColumn)
    }
    if (params?.roomName) {
      request.setRoomName(params?.roomName)
    }

    return new Promise((resolve, reject) => {
      client.listMessages(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          const result = response.toObject()
          resolve({
            ...result,
            messagesList: result.messagesList.map(toMessageObject)
          })
        }
      })
    })
  },
  getChatMessage: (name) => {
    const request = new GetMessageRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.getMessage(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(toMessageObject(response.toObject()))
        }
      });
    });
  },
  applyChatMessage: (instance) => {
    console.log('applyChatMessage', instance)
    const applying = toMessageModel(instance)
    const request = new ApplyMessageRequest()
    request.setMessage(applying)
    return new Promise((resolve, reject) => {
      client.applyMessage(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(toMessageObject(response.toObject()))
        }
      })
    })
  },
  deleteChatMessage: (name) => {
    const request = new DeleteMessageRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.deleteMessage(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  }
};

export default API;
