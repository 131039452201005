/**
 * @fileoverview gRPC-Web generated client stub for dating.erp.api.v1.services
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: projects/erp/service/api/v1/services/customer_likes.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var projects_erp_service_api_v1_model_customer_like_pb = require('./customer_like_pb.js')
const proto = {};
proto.dating = {};
proto.dating.erp = {};
proto.dating.erp.api = {};
proto.dating.erp.api.v1 = {};
proto.dating.erp.api.v1.services = require('./customer_likes_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dating.erp.api.v1.services.CustomerLikesClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dating.erp.api.v1.services.CustomerLikesPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.ListCustomerLikesRequest,
 *   !proto.dating.erp.api.v1.services.ListCustomerLikesResponse>}
 */
const methodDescriptor_CustomerLikes_ListCustomerLikes = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.CustomerLikes/ListCustomerLikes',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.ListCustomerLikesRequest,
  proto.dating.erp.api.v1.services.ListCustomerLikesResponse,
  /**
   * @param {!proto.dating.erp.api.v1.services.ListCustomerLikesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dating.erp.api.v1.services.ListCustomerLikesResponse.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.ListCustomerLikesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.services.ListCustomerLikesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.services.ListCustomerLikesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.CustomerLikesClient.prototype.listCustomerLikes =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.CustomerLikes/ListCustomerLikes',
      request,
      metadata || {},
      methodDescriptor_CustomerLikes_ListCustomerLikes,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.ListCustomerLikesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.services.ListCustomerLikesResponse>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.CustomerLikesPromiseClient.prototype.listCustomerLikes =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.CustomerLikes/ListCustomerLikes',
      request,
      metadata || {},
      methodDescriptor_CustomerLikes_ListCustomerLikes);
};


module.exports = proto.dating.erp.api.v1.services;

