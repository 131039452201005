import { ApiUrl, getMetadata } from '../index'
import { EmailTopic } from './email_topic_pb'
import {
  ListEmailTopicsRequest,
  ApplyEmailTopicRequest
} from './email_topics_pb'
import { EmailTopicsClient } from './email_topics_grpc_web_pb'
import wrappers from 'google-protobuf/google/protobuf/wrappers_pb'

let client = new EmailTopicsClient(ApiUrl, null, null);

const toEmailTopicModel = (instance) => {
  let result = new EmailTopic()
  result.setThreadIndex(instance.threadIndex)
  if (instance?.customerName) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.customerName)
    result.setCustomerName(wrapped)
  }

  return result
}

const toEmailTopicObject = (instance) => {
  const result = {}
  Object.keys(instance).forEach(key => {
    if (key.endsWith('List') || key.endsWith('Map') || key === "amount") {
      result[key] = instance[key]
    } else if (instance[key]?.value) {
      result[key] = instance[key]?.value
    } else {
      result[key] = instance[key]
    }
  })
  return result
}

const API = {
  listEmailTopics: (params) => {
    const request = new ListEmailTopicsRequest();
    if (params?.page) {
      request.setPage(params.page)
    }
    if (params?.perPage) {
      request.setPerPage(params.perPage)
    }
    if (params?.sort) {
      request.setSort(params.sort)
    }
    if (params?.sortColumn) {
      request.setSortColumn(params.sortColumn)
    }
    if (params?.customerName) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.customerName)
      request.setCustomerName(wrapped)
    }
    if (params?.folderDisplayName) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.folderDisplayName)
      request.setFolderDisplayName(wrapped)
    }
    if (params?.search) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.search)
      request.setSearch(wrapped)
    }
    if (params?.threadIndex) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.threadIndex)
      request.setThreadIndex(wrapped)
    }

    return new Promise((resolve, reject) => {
      client.listEmailTopics(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          const result = response.toObject()
          resolve({
            ...result,
            emailTopicsList: result.emailTopicsList.map(toEmailTopicObject)
          })
        }
      })
    })
  },
  applyEmailTopic: (instance) => {
    const applying = toEmailTopicModel(instance)
    const request = new ApplyEmailTopicRequest()
    request.setEmailTopic(applying)
    return new Promise((resolve, reject) => {
      client.applyEmailTopic(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(toEmailTopicObject(response.toObject()))
        }
      })
    })
  }
};

export default API;
