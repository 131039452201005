/**
 * @fileoverview gRPC-Web generated client stub for dating.erp.api.v1.services
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: projects/erp/service/api/v1/services/legals.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_field_mask_pb = require('google-protobuf/google/protobuf/field_mask_pb.js')

var projects_erp_service_api_v1_model_legal_pb = require('./legal_pb.js')
const proto = {};
proto.dating = {};
proto.dating.erp = {};
proto.dating.erp.api = {};
proto.dating.erp.api.v1 = {};
proto.dating.erp.api.v1.services = require('./legals_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dating.erp.api.v1.services.LegalsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dating.erp.api.v1.services.LegalsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.ListLegalsRequest,
 *   !proto.dating.erp.api.v1.services.ListLegalsResponse>}
 */
const methodDescriptor_Legals_ListLegals = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Legals/ListLegals',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.ListLegalsRequest,
  proto.dating.erp.api.v1.services.ListLegalsResponse,
  /**
   * @param {!proto.dating.erp.api.v1.services.ListLegalsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dating.erp.api.v1.services.ListLegalsResponse.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.ListLegalsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.services.ListLegalsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.services.ListLegalsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.LegalsClient.prototype.listLegals =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Legals/ListLegals',
      request,
      metadata || {},
      methodDescriptor_Legals_ListLegals,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.ListLegalsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.services.ListLegalsResponse>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.LegalsPromiseClient.prototype.listLegals =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Legals/ListLegals',
      request,
      metadata || {},
      methodDescriptor_Legals_ListLegals);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.GetLegalRequest,
 *   !proto.dating.erp.api.v1.model.Legal>}
 */
const methodDescriptor_Legals_GetLegal = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Legals/GetLegal',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.GetLegalRequest,
  projects_erp_service_api_v1_model_legal_pb.Legal,
  /**
   * @param {!proto.dating.erp.api.v1.services.GetLegalRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_legal_pb.Legal.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.GetLegalRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.Legal)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.Legal>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.LegalsClient.prototype.getLegal =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Legals/GetLegal',
      request,
      metadata || {},
      methodDescriptor_Legals_GetLegal,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.GetLegalRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.Legal>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.LegalsPromiseClient.prototype.getLegal =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Legals/GetLegal',
      request,
      metadata || {},
      methodDescriptor_Legals_GetLegal);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.ApplyLegalRequest,
 *   !proto.dating.erp.api.v1.model.Legal>}
 */
const methodDescriptor_Legals_ApplyLegal = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Legals/ApplyLegal',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.ApplyLegalRequest,
  projects_erp_service_api_v1_model_legal_pb.Legal,
  /**
   * @param {!proto.dating.erp.api.v1.services.ApplyLegalRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_legal_pb.Legal.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.ApplyLegalRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.Legal)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.Legal>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.LegalsClient.prototype.applyLegal =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Legals/ApplyLegal',
      request,
      metadata || {},
      methodDescriptor_Legals_ApplyLegal,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.ApplyLegalRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.Legal>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.LegalsPromiseClient.prototype.applyLegal =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Legals/ApplyLegal',
      request,
      metadata || {},
      methodDescriptor_Legals_ApplyLegal);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.DeleteLegalRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Legals_DeleteLegal = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Legals/DeleteLegal',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.DeleteLegalRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.dating.erp.api.v1.services.DeleteLegalRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.DeleteLegalRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.LegalsClient.prototype.deleteLegal =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Legals/DeleteLegal',
      request,
      metadata || {},
      methodDescriptor_Legals_DeleteLegal,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.DeleteLegalRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.LegalsPromiseClient.prototype.deleteLegal =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Legals/DeleteLegal',
      request,
      metadata || {},
      methodDescriptor_Legals_DeleteLegal);
};


module.exports = proto.dating.erp.api.v1.services;

