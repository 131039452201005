import { ApiUrl, getMetadata } from '../index'
import { Room } from './room_pb'
import {
  GetRoomRequest,
  ListRoomsRequest,
  ApplyRoomRequest,
  DeleteRoomRequest
} from './rooms_pb'
import { RoomsClient } from './rooms_grpc_web_pb'
import wrappers from 'google-protobuf/google/protobuf/wrappers_pb'

let client = new RoomsClient(ApiUrl, null, null);

const toRoomModel = (instance) => {
  let result = new Room()
  result.setName(instance.name)
  result.setRoomName(instance.roomName)
  result.setUserName(instance.userName)
  result.setText(instance.text)

  if (instance.replyRoomName) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.replyRoomName)
    result.setReplyRoomName(wrapped)
  }

  return result
}

const toRoomObject = (instance) => {
  const result = {}
  Object.keys(instance).forEach(key => {
    if (key.endsWith('List') || key.endsWith('Map') || key === "amount") {
      result[key] = instance[key]
    } else if (instance[key]?.value) {
      result[key] = instance[key]?.value
    } else {
      result[key] = instance[key]
    }
  })
  return result
}

const API = {
  listChatRooms: (params) => {
    const request = new ListRoomsRequest();
    if (params?.page) {
      request.setPage(params.page)
    }
    if (params?.perPage) {
      request.setPerPage(params.perPage)
    }
    if (params?.sort) {
      request.setSort(params.sort)
    }
    if (params?.sortColumn) {
      request.setSortColumn(params.sortColumn)
    }
    if (params?.customerName) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.customerName)
      request.setCustomerName(wrapped)
    }
    if (params?.supportOnly !== undefined) {
      const wrapped = new wrappers.BoolValue()
      wrapped.setValue(!!params?.supportOnly)
      request.setSupportOnly(wrapped)
    }

    return new Promise((resolve, reject) => {
      client.listRooms(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          const result = response.toObject()
          resolve({
            ...result,
            roomsList: result.roomsList.map(toRoomObject)
          })
        }
      })
    })
  },
  getChatRoom: (name) => {
    const request = new GetRoomRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.getRoom(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(toRoomObject(response.toObject()))
        }
      });
    });
  },
  applyChatRoom: (instance) => {
    const applying = toRoomModel(instance)
    const request = new ApplyRoomRequest()
    request.setRoom(applying)
    return new Promise((resolve, reject) => {
      client.applyRoom(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(toRoomObject(response.toObject()))
        }
      })
    })
  },
  deleteChatRoom: (name) => {
    const request = new DeleteRoomRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.deleteRoom(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  }
};

export default API;
