import { ApiUrl, getMetadata } from '../index'
import { CustomerLike } from './customer_like_pb'
import {
  ListCustomerLikesRequest
} from './customer_likes_pb'
import { CustomerLikesClient } from './customer_likes_grpc_web_pb'
import wrappers from 'google-protobuf/google/protobuf/wrappers_pb'
import { ListOfStrings } from '../list_of_strings_pb'
import { Date } from '../date_pb'
import { mapToProto, toMap } from 'utility/utils'

let client = new CustomerLikesClient(ApiUrl, null, null);

const toCustomerLikeObject = (instance) => {
  const result = {}
  Object.keys(instance).forEach(key => {
    if (key.endsWith('List') || key.endsWith('Map') || key === "amount") {
      result[key] = instance[key]
    } else if (instance[key]?.value) {
      result[key] = instance[key]?.value
    } else {
      result[key] = instance[key]
    }
  })
  return result
}

const API = {
  listCustomerLikes: (params) => {
    const request = new ListCustomerLikesRequest();
    if (params?.page) {
      request.setPage(params.page)
    }
    if (params?.perPage) {
      request.setPerPage(params.perPage)
    }
    if (params?.sort) {
      request.setSort(params.sort)
    }
    if (params?.sortColumn) {
      request.setSortColumn(params.sortColumn)
    }
    if (params?.customerName) {
      request.setCustomerName(params?.customerName)
    }

    return new Promise((resolve, reject) => {
      client.listCustomerLikes(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          const result = response.toObject()
          resolve({
            ...result,
            customerLikesList: result.customerLikesList.map(toCustomerLikeObject)
          })
        }
      })
    })
  }
};

export default API;
