/**
 * @fileoverview gRPC-Web generated client stub for dating.erp.api.v1.services
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: projects/erp/service/api/v1/services/affilate_systems.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var projects_erp_service_api_v1_model_affilate_system_pb = require('./affilate_system_pb.js')
const proto = {};
proto.dating = {};
proto.dating.erp = {};
proto.dating.erp.api = {};
proto.dating.erp.api.v1 = {};
proto.dating.erp.api.v1.services = require('./affilate_systems_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dating.erp.api.v1.services.AffilateSystemsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dating.erp.api.v1.services.AffilateSystemsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.ListAffilateSystemsRequest,
 *   !proto.dating.erp.api.v1.services.ListAffilateSystemsResponse>}
 */
const methodDescriptor_AffilateSystems_ListAffilateSystems = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.AffilateSystems/ListAffilateSystems',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.ListAffilateSystemsRequest,
  proto.dating.erp.api.v1.services.ListAffilateSystemsResponse,
  /**
   * @param {!proto.dating.erp.api.v1.services.ListAffilateSystemsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dating.erp.api.v1.services.ListAffilateSystemsResponse.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.ListAffilateSystemsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.services.ListAffilateSystemsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.services.ListAffilateSystemsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.AffilateSystemsClient.prototype.listAffilateSystems =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.AffilateSystems/ListAffilateSystems',
      request,
      metadata || {},
      methodDescriptor_AffilateSystems_ListAffilateSystems,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.ListAffilateSystemsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.services.ListAffilateSystemsResponse>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.AffilateSystemsPromiseClient.prototype.listAffilateSystems =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.AffilateSystems/ListAffilateSystems',
      request,
      metadata || {},
      methodDescriptor_AffilateSystems_ListAffilateSystems);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.GetAffilateSystemRequest,
 *   !proto.dating.erp.api.v1.model.AffilateSystem>}
 */
const methodDescriptor_AffilateSystems_GetAffilateSystem = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.AffilateSystems/GetAffilateSystem',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.GetAffilateSystemRequest,
  projects_erp_service_api_v1_model_affilate_system_pb.AffilateSystem,
  /**
   * @param {!proto.dating.erp.api.v1.services.GetAffilateSystemRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_affilate_system_pb.AffilateSystem.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.GetAffilateSystemRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.AffilateSystem)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.AffilateSystem>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.AffilateSystemsClient.prototype.getAffilateSystem =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.AffilateSystems/GetAffilateSystem',
      request,
      metadata || {},
      methodDescriptor_AffilateSystems_GetAffilateSystem,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.GetAffilateSystemRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.AffilateSystem>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.AffilateSystemsPromiseClient.prototype.getAffilateSystem =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.AffilateSystems/GetAffilateSystem',
      request,
      metadata || {},
      methodDescriptor_AffilateSystems_GetAffilateSystem);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.ApplyAffilateSystemRequest,
 *   !proto.dating.erp.api.v1.model.AffilateSystem>}
 */
const methodDescriptor_AffilateSystems_ApplyAffilateSystem = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.AffilateSystems/ApplyAffilateSystem',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.ApplyAffilateSystemRequest,
  projects_erp_service_api_v1_model_affilate_system_pb.AffilateSystem,
  /**
   * @param {!proto.dating.erp.api.v1.services.ApplyAffilateSystemRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_affilate_system_pb.AffilateSystem.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.ApplyAffilateSystemRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.AffilateSystem)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.AffilateSystem>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.AffilateSystemsClient.prototype.applyAffilateSystem =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.AffilateSystems/ApplyAffilateSystem',
      request,
      metadata || {},
      methodDescriptor_AffilateSystems_ApplyAffilateSystem,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.ApplyAffilateSystemRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.AffilateSystem>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.AffilateSystemsPromiseClient.prototype.applyAffilateSystem =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.AffilateSystems/ApplyAffilateSystem',
      request,
      metadata || {},
      methodDescriptor_AffilateSystems_ApplyAffilateSystem);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.DeleteAffilateSystemRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_AffilateSystems_DeleteAffilateSystem = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.AffilateSystems/DeleteAffilateSystem',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.DeleteAffilateSystemRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.dating.erp.api.v1.services.DeleteAffilateSystemRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.DeleteAffilateSystemRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.AffilateSystemsClient.prototype.deleteAffilateSystem =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.AffilateSystems/DeleteAffilateSystem',
      request,
      metadata || {},
      methodDescriptor_AffilateSystems_DeleteAffilateSystem,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.DeleteAffilateSystemRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.AffilateSystemsPromiseClient.prototype.deleteAffilateSystem =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.AffilateSystems/DeleteAffilateSystem',
      request,
      metadata || {},
      methodDescriptor_AffilateSystems_DeleteAffilateSystem);
};


module.exports = proto.dating.erp.api.v1.services;

