/**
 * @fileoverview gRPC-Web generated client stub for dating.erp.api.v1.services
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: projects/erp/service/api/v1/services/quarters.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

var projects_erp_service_api_v1_model_quarter_pb = require('./quarter_pb.js')
const proto = {};
proto.dating = {};
proto.dating.erp = {};
proto.dating.erp.api = {};
proto.dating.erp.api.v1 = {};
proto.dating.erp.api.v1.services = require('./quarters_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dating.erp.api.v1.services.QuartersClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dating.erp.api.v1.services.QuartersPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.ListQuartersRequest,
 *   !proto.dating.erp.api.v1.services.ListQuartersResponse>}
 */
const methodDescriptor_Quarters_ListQuarters = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Quarters/ListQuarters',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.ListQuartersRequest,
  proto.dating.erp.api.v1.services.ListQuartersResponse,
  /**
   * @param {!proto.dating.erp.api.v1.services.ListQuartersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dating.erp.api.v1.services.ListQuartersResponse.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.ListQuartersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.services.ListQuartersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.services.ListQuartersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.QuartersClient.prototype.listQuarters =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Quarters/ListQuarters',
      request,
      metadata || {},
      methodDescriptor_Quarters_ListQuarters,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.ListQuartersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.services.ListQuartersResponse>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.QuartersPromiseClient.prototype.listQuarters =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Quarters/ListQuarters',
      request,
      metadata || {},
      methodDescriptor_Quarters_ListQuarters);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.GetQuarterRequest,
 *   !proto.dating.erp.api.v1.model.Quarter>}
 */
const methodDescriptor_Quarters_GetQuarter = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Quarters/GetQuarter',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.GetQuarterRequest,
  projects_erp_service_api_v1_model_quarter_pb.Quarter,
  /**
   * @param {!proto.dating.erp.api.v1.services.GetQuarterRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_quarter_pb.Quarter.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.GetQuarterRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.Quarter)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.Quarter>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.QuartersClient.prototype.getQuarter =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Quarters/GetQuarter',
      request,
      metadata || {},
      methodDescriptor_Quarters_GetQuarter,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.GetQuarterRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.Quarter>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.QuartersPromiseClient.prototype.getQuarter =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Quarters/GetQuarter',
      request,
      metadata || {},
      methodDescriptor_Quarters_GetQuarter);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.ApplyQuarterRequest,
 *   !proto.dating.erp.api.v1.model.Quarter>}
 */
const methodDescriptor_Quarters_ApplyQuarter = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Quarters/ApplyQuarter',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.ApplyQuarterRequest,
  projects_erp_service_api_v1_model_quarter_pb.Quarter,
  /**
   * @param {!proto.dating.erp.api.v1.services.ApplyQuarterRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_quarter_pb.Quarter.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.ApplyQuarterRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.Quarter)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.Quarter>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.QuartersClient.prototype.applyQuarter =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Quarters/ApplyQuarter',
      request,
      metadata || {},
      methodDescriptor_Quarters_ApplyQuarter,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.ApplyQuarterRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.Quarter>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.QuartersPromiseClient.prototype.applyQuarter =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Quarters/ApplyQuarter',
      request,
      metadata || {},
      methodDescriptor_Quarters_ApplyQuarter);
};


module.exports = proto.dating.erp.api.v1.services;

