/**
 * @fileoverview gRPC-Web generated client stub for dating.erp.api.v1.services
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: projects/erp/service/api/v1/services/collection_agencies.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var projects_erp_service_api_v1_model_collection_agency_pb = require('./collection_agency_pb.js')

const proto = {};
proto.dating = {};
proto.dating.erp = {};
proto.dating.erp.api = {};
proto.dating.erp.api.v1 = {};
proto.dating.erp.api.v1.services = require('./collection_agencies_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dating.erp.api.v1.services.CollectionAgenciesClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dating.erp.api.v1.services.CollectionAgenciesPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.ListCollectionAgenciesRequest,
 *   !proto.dating.erp.api.v1.services.ListCollectionAgenciesResponse>}
 */
const methodDescriptor_CollectionAgencies_ListCollectionAgencies = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.CollectionAgencies/ListCollectionAgencies',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.ListCollectionAgenciesRequest,
  proto.dating.erp.api.v1.services.ListCollectionAgenciesResponse,
  /**
   * @param {!proto.dating.erp.api.v1.services.ListCollectionAgenciesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dating.erp.api.v1.services.ListCollectionAgenciesResponse.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.ListCollectionAgenciesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.services.ListCollectionAgenciesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.services.ListCollectionAgenciesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.CollectionAgenciesClient.prototype.listCollectionAgencies =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.CollectionAgencies/ListCollectionAgencies',
      request,
      metadata || {},
      methodDescriptor_CollectionAgencies_ListCollectionAgencies,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.ListCollectionAgenciesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.services.ListCollectionAgenciesResponse>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.CollectionAgenciesPromiseClient.prototype.listCollectionAgencies =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.CollectionAgencies/ListCollectionAgencies',
      request,
      metadata || {},
      methodDescriptor_CollectionAgencies_ListCollectionAgencies);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.GetCollectionAgencyRequest,
 *   !proto.dating.erp.api.v1.model.CollectionAgency>}
 */
const methodDescriptor_CollectionAgencies_GetCollectionAgency = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.CollectionAgencies/GetCollectionAgency',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.GetCollectionAgencyRequest,
  projects_erp_service_api_v1_model_collection_agency_pb.CollectionAgency,
  /**
   * @param {!proto.dating.erp.api.v1.services.GetCollectionAgencyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_collection_agency_pb.CollectionAgency.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.GetCollectionAgencyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.CollectionAgency)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.CollectionAgency>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.CollectionAgenciesClient.prototype.getCollectionAgency =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.CollectionAgencies/GetCollectionAgency',
      request,
      metadata || {},
      methodDescriptor_CollectionAgencies_GetCollectionAgency,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.GetCollectionAgencyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.CollectionAgency>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.CollectionAgenciesPromiseClient.prototype.getCollectionAgency =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.CollectionAgencies/GetCollectionAgency',
      request,
      metadata || {},
      methodDescriptor_CollectionAgencies_GetCollectionAgency);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.ApplyCollectionAgencyRequest,
 *   !proto.dating.erp.api.v1.model.CollectionAgency>}
 */
const methodDescriptor_CollectionAgencies_ApplyCollectionAgency = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.CollectionAgencies/ApplyCollectionAgency',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.ApplyCollectionAgencyRequest,
  projects_erp_service_api_v1_model_collection_agency_pb.CollectionAgency,
  /**
   * @param {!proto.dating.erp.api.v1.services.ApplyCollectionAgencyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_collection_agency_pb.CollectionAgency.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.ApplyCollectionAgencyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.CollectionAgency)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.CollectionAgency>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.CollectionAgenciesClient.prototype.applyCollectionAgency =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.CollectionAgencies/ApplyCollectionAgency',
      request,
      metadata || {},
      methodDescriptor_CollectionAgencies_ApplyCollectionAgency,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.ApplyCollectionAgencyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.CollectionAgency>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.CollectionAgenciesPromiseClient.prototype.applyCollectionAgency =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.CollectionAgencies/ApplyCollectionAgency',
      request,
      metadata || {},
      methodDescriptor_CollectionAgencies_ApplyCollectionAgency);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.DeleteCollectionAgencyRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_CollectionAgencies_DeleteCollectionAgency = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.CollectionAgencies/DeleteCollectionAgency',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.DeleteCollectionAgencyRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.dating.erp.api.v1.services.DeleteCollectionAgencyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.DeleteCollectionAgencyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.CollectionAgenciesClient.prototype.deleteCollectionAgency =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.CollectionAgencies/DeleteCollectionAgency',
      request,
      metadata || {},
      methodDescriptor_CollectionAgencies_DeleteCollectionAgency,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.DeleteCollectionAgencyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.CollectionAgenciesPromiseClient.prototype.deleteCollectionAgency =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.CollectionAgencies/DeleteCollectionAgency',
      request,
      metadata || {},
      methodDescriptor_CollectionAgencies_DeleteCollectionAgency);
};


module.exports = proto.dating.erp.api.v1.services;

