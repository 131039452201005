// ** React Imports
import { lazy } from 'react'

const CustomerCategoriesList = lazy(() => import('views/references/customer-categories/list'))
const EmailMessageTemplatesList = lazy(() => import('views/references/email-message-templates/list'))
const InvoiceTypesList = lazy(() => import('views/references/invoice-types/list'))
const PaymentTypesList = lazy(() => import('views/references/payment-types/list'))
const AffilateSystemsList = lazy(() => import('views/references/affilate-systems/list'))

const ReferencesRoutes = [
  {
    element: <CustomerCategoriesList />,
    path: '/customerCategories',
    meta: {
      resource: 'customerCategories',
      action: 'read'
    }
  },
  {
    element: <InvoiceTypesList />,
    path: '/invoiceTypes',
    meta: {
      resource: 'invoiceTypes',
      action: 'read'
    }
  },
  {
    element: <PaymentTypesList />,
    path: '/paymentTypes',
    meta: {
      resource: 'paymentTypes',
      action: 'read'
    }
  },
  {
    element: <EmailMessageTemplatesList />,
    path: '/emailTemplates',
    meta: {
      resource: 'emailTemplates',
      action: 'read'
    }
  },
  {
    element: <AffilateSystemsList />,
    path: '/affilateSystems',
    meta: {
      resource: 'affilateSystems',
      action: 'read'
    }
  }
]

export default ReferencesRoutes
