// ** React Imports
import { Suspense } from 'react'
import { Redirect } from 'react-router-dom'

// ** Utils
import { getUserData, getHomeRouteForLoggedInUser } from 'utility/utils'

const PublicRoute = ({ children, route }) => {
  if (route) {
    const user = getUserData()

    const restrictedRoute = route.meta && route.meta.restricted

    if (user && restrictedRoute) {
      return <Redirect to={getHomeRouteForLoggedInUser(user.role)} />
    }
  }

  return <Suspense fallback={null}>{children}</Suspense>
}

export default PublicRoute
