import { ApiUrl, getMetadata } from '../index'
import {
  ListAffilateConversionsRequest
} from './affilate_conversions_pb'
import { AffilateConversionsClient } from './affilate_conversions_grpc_web_pb'
import wrappers from 'google-protobuf/google/protobuf/wrappers_pb'

let client = new AffilateConversionsClient(ApiUrl, null, null);

const API = {
  listAffilateConversions: (params) => {
    const request = new ListAffilateConversionsRequest()
    if (params?.page) {
      request.setPage(params.page)
    }
    if (params?.perPage) {
      request.setPerPage(params.perPage)
    }
    if (params?.sort) {
      request.setSort(params.sort == "asc")
    }
    if (params?.sortColumn) {
      request.setSortColumn(params.sortColumn)
    }
    if (params?.systemName) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.systemName)
      request.setSystemName(wrapped)
    }
    if (params?.customerName) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.customerName)
      request.setCustomerName(wrapped)
    }
    if (params?.conversionCode) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.conversionCode)
      request.setConversionCode(wrapped)
    }
    if (params?.conversionAction) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.conversionAction)
      request.setConversionAction(wrapped)
    }
    if (params?.search) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.search)
      request.setSearch(wrapped)
    }
    return new Promise((resolve, reject) => {
      client.listAffilateConversions(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  }
};

export default API;
