/**
 * @fileoverview gRPC-Web generated client stub for dating.erp.api.v1.services
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: projects/erp/service/api/v1/services/districts.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

var projects_erp_service_api_v1_model_district_pb = require('./district_pb.js')
const proto = {};
proto.dating = {};
proto.dating.erp = {};
proto.dating.erp.api = {};
proto.dating.erp.api.v1 = {};
proto.dating.erp.api.v1.services = require('./districts_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dating.erp.api.v1.services.DistrictsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dating.erp.api.v1.services.DistrictsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.ListDistrictsRequest,
 *   !proto.dating.erp.api.v1.services.ListDistrictsResponse>}
 */
const methodDescriptor_Districts_ListDistricts = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Districts/ListDistricts',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.ListDistrictsRequest,
  proto.dating.erp.api.v1.services.ListDistrictsResponse,
  /**
   * @param {!proto.dating.erp.api.v1.services.ListDistrictsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dating.erp.api.v1.services.ListDistrictsResponse.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.ListDistrictsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.services.ListDistrictsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.services.ListDistrictsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.DistrictsClient.prototype.listDistricts =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Districts/ListDistricts',
      request,
      metadata || {},
      methodDescriptor_Districts_ListDistricts,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.ListDistrictsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.services.ListDistrictsResponse>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.DistrictsPromiseClient.prototype.listDistricts =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Districts/ListDistricts',
      request,
      metadata || {},
      methodDescriptor_Districts_ListDistricts);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.GetDistrictRequest,
 *   !proto.dating.erp.api.v1.model.District>}
 */
const methodDescriptor_Districts_GetDistrict = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Districts/GetDistrict',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.GetDistrictRequest,
  projects_erp_service_api_v1_model_district_pb.District,
  /**
   * @param {!proto.dating.erp.api.v1.services.GetDistrictRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_district_pb.District.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.GetDistrictRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.District)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.District>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.DistrictsClient.prototype.getDistrict =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Districts/GetDistrict',
      request,
      metadata || {},
      methodDescriptor_Districts_GetDistrict,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.GetDistrictRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.District>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.DistrictsPromiseClient.prototype.getDistrict =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Districts/GetDistrict',
      request,
      metadata || {},
      methodDescriptor_Districts_GetDistrict);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.ApplyDistrictRequest,
 *   !proto.dating.erp.api.v1.model.District>}
 */
const methodDescriptor_Districts_ApplyDistrict = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Districts/ApplyDistrict',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.ApplyDistrictRequest,
  projects_erp_service_api_v1_model_district_pb.District,
  /**
   * @param {!proto.dating.erp.api.v1.services.ApplyDistrictRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_district_pb.District.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.ApplyDistrictRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.District)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.District>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.DistrictsClient.prototype.applyDistrict =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Districts/ApplyDistrict',
      request,
      metadata || {},
      methodDescriptor_Districts_ApplyDistrict,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.ApplyDistrictRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.District>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.DistrictsPromiseClient.prototype.applyDistrict =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Districts/ApplyDistrict',
      request,
      metadata || {},
      methodDescriptor_Districts_ApplyDistrict);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.DeleteDistrictRequest,
 *   !proto.dating.erp.api.v1.services.DeleteDistrictResponse>}
 */
const methodDescriptor_Districts_DeleteDistrict = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Districts/DeleteDistrict',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.DeleteDistrictRequest,
  proto.dating.erp.api.v1.services.DeleteDistrictResponse,
  /**
   * @param {!proto.dating.erp.api.v1.services.DeleteDistrictRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dating.erp.api.v1.services.DeleteDistrictResponse.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.DeleteDistrictRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.services.DeleteDistrictResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.services.DeleteDistrictResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.DistrictsClient.prototype.deleteDistrict =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Districts/DeleteDistrict',
      request,
      metadata || {},
      methodDescriptor_Districts_DeleteDistrict,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.DeleteDistrictRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.services.DeleteDistrictResponse>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.DistrictsPromiseClient.prototype.deleteDistrict =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Districts/DeleteDistrict',
      request,
      metadata || {},
      methodDescriptor_Districts_DeleteDistrict);
};


module.exports = proto.dating.erp.api.v1.services;

