// ** Icons Import
import { Archive, Folder, Users } from 'react-feather'

export default [
  {
    header: 'Collection',
    resource: 'collection',
    action: 'read'
  },
  {
    id: 'debtors',
    title: 'Debtors',
    icon: <Folder size={20} />,
    navLink: '/debtors',
    resource: 'debtors',
    action: 'read'
  },
  {
    id: 'collectionExports',
    title: 'Exports',
    icon: <Archive size={20} />,
    navLink: '/collectionExports',
    resource: 'collectionExports',
    action: 'read'
  },
  {
    id: 'collectionAgencies',
    title: 'Agencies',
    icon: <Users size={20} />,
    navLink: '/collectionAgencies',
    resource: 'collectionAgencies',
    action: 'read'
  }
]
