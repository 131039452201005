import { ApiUrl, getMetadata } from '../index'
import { AffilateSystem, AffilateSystemConversion } from './affilate_system_pb'
import {
  GetAffilateSystemRequest,
  ListAffilateSystemsRequest,
  ApplyAffilateSystemRequest,
  DeleteAffilateSystemRequest
} from './affilate_systems_pb'
import { AffilateSystemsClient } from './affilate_systems_grpc_web_pb'

let client = new AffilateSystemsClient(ApiUrl, null, null);

const toAffilateSystemModel = (instance) => {
  const result = new AffilateSystem()
  result.setName(instance.name)
  result.setName(instance.displayName)
  result.setName(instance.url)
  if ((instance?.domainsList ?? []).length) {
    result.setDomainsList(instance.domainsList)
  }
  result.setIsActive(instance.isActive)
  if ((instance?.conversionsList ?? []).length) {
    result.setConversionsList(instance.conversionsList.map(c => {
      const conversion = new AffilateSystemConversion()
      conversion.setAction(c.action)
      if ((c.codesList ?? []).length) {
        conversion.setCodesList(c.codesList)
      }
      conversion.setPayout(c.payout)
      return conversion
    }))
  }

  return result
}

const API = {
  listAffilateSystems: () => {
    const request = new ListAffilateSystemsRequest()
    return new Promise((resolve, reject) => {
      client.listAffilateSystems(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject().affilateSystemsList)
        }
      });
    });
  },
  getAffilateSystem: (name) => {
    const request = new GetAffilateSystemRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.getAffilateSystem(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  applyAffilateSystem: (instance) => {
    const applying = toAffilateSystemModel(instance)
    const request = new ApplyAffilateSystemRequest()
    request.setAffilateSystem(applying)
    return new Promise((resolve, reject) => {
      client.applyAffilateSystem(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      })
    })
  },
  deleteAffilateSystem: (name) => {
    const request = new DeleteAffilateSystemRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.deleteAffilateSystem(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  }
};

export default API;
