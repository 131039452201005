import { ApiUrl, getMetadata } from '../index'
import { CollectionExport, CollectionExportBlock } from './collection_export_pb'
import {
  GetCollectionExportRequest,
  ListCollectionExportsRequest,
  ApplyCollectionExportRequest,
  DeleteCollectionExportRequest
} from './collection_exports_pb'
import { CollectionExportsClient } from './collection_exports_grpc_web_pb'
import wrappers from 'google-protobuf/google/protobuf/wrappers_pb'
import { toCustomerModel } from '../customers'

let client = new CollectionExportsClient(ApiUrl, null, null);

const toCollectionExportModel = (instance) => {
  let result = new CollectionExport()
  result.setName(instance.name)
  result.setDisplayName(instance.displayName)
  result.setAgencyName(instance.agencyName)
  if (instance.customersList) {
    result.setCustomersList(instance.customersList.map(e => toCustomerModel(e)))
  }
  return result
}

const API = {
  listCollectionExports: (params) => {
    const request = new ListCollectionExportsRequest()
    if (params?.page) {
      request.setPage(params.page)
    }
    if (params?.perPage) {
      request.setPerPage(params.perPage)
    }
    if (params?.sort) {
      request.setSort(params.sort)
    }
    if (params?.sortColumn) {
      request.setSortColumn(params.sortColumn)
    }
    if (params?.search) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.search)
      request.setSearch(wrapped)
    }
    return new Promise((resolve, reject) => {
      client.listCollectionExports(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  getCollectionExport: (name) => {
    const request = new GetCollectionExportRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.getCollectionExport(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  applyCollectionExport: (instance) => {
    const applying = toCollectionExportModel(instance)
    const request = new ApplyCollectionExportRequest();
    request.setCollectionExport(applying)
    return new Promise((resolve, reject) => {
      client.applyCollectionExport(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      })
    })
  },
  deleteCollectionExport: (name) => {
    const request = new DeleteCollectionExportRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.deleteCollectionExport(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  }
};

export default API;
