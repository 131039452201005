// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import API from 'api/collection_agencies'

export const list = createAsyncThunk('appCollectionAgencies/list', async () => {
  const response = await API.listCollectionAgencies()
  return response.collectionAgenciesList
})

export const get = createAsyncThunk('appCollectionAgencies/get', async name => {
  return await API.getCollectionAgency(name)
})

export const add = createAsyncThunk('appCollectionAgencies/add', async (instance, { dispatch }) => {
  const updated = await API.applyCollectionAgency(instance)
  instance = {
    ...instance,
    updated
  }
  await dispatch(list())
  return instance
})

export const modify = createAsyncThunk('appCollectionAgencies/modify', async (instance, { dispatch }) => {
  const updated = await API.applyCollectionAgency(instance)
  instance = {
    ...instance,
    updated
  }
  await dispatch(appCollectionAgenciesSlice.actions.setSelected(instance))
  await dispatch(list())
})

export const del = createAsyncThunk('appCollectionAgencies/del', async (name, { dispatch }) => {
  await API.deleteCollectionAgency(name)
  await dispatch(list())
  return name
})

export const appCollectionAgenciesSlice = createSlice({
  name: 'appCollectionAgencies',
  initialState: {
    data: [],
    selected: null
  },
  reducers: {
    setSelected: (state, { payload }) => {
      state.selected = payload
    }
  },  extraReducers: builder => {
    builder
      .addCase(list.fulfilled, (state, action) => {
        state.data = action.payload
      })
      .addCase(get.fulfilled, (state, { payload }) => {
        state.selected = payload
      })
  }
})

export default appCollectionAgenciesSlice.reducer
