/**
 * @fileoverview gRPC-Web generated client stub for dating.erp.api.v1.services
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: projects/erp/service/api/v1/services/users.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


const proto = {};
proto.dating = {};
proto.dating.erp = {};
proto.dating.erp.api = {};
proto.dating.erp.api.v1 = {};
proto.dating.erp.api.v1.services = require('./users_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dating.erp.api.v1.services.UsersClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dating.erp.api.v1.services.UsersPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.LoginUserRequest,
 *   !proto.dating.erp.api.v1.services.LoginUserResponse>}
 */
const methodDescriptor_Users_LoginUser = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Users/LoginUser',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.LoginUserRequest,
  proto.dating.erp.api.v1.services.LoginUserResponse,
  /**
   * @param {!proto.dating.erp.api.v1.services.LoginUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dating.erp.api.v1.services.LoginUserResponse.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.LoginUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.services.LoginUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.services.LoginUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.UsersClient.prototype.loginUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Users/LoginUser',
      request,
      metadata || {},
      methodDescriptor_Users_LoginUser,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.LoginUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.services.LoginUserResponse>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.UsersPromiseClient.prototype.loginUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Users/LoginUser',
      request,
      metadata || {},
      methodDescriptor_Users_LoginUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.RefreshTokenUserRequest,
 *   !proto.dating.erp.api.v1.services.LoginUserResponse>}
 */
const methodDescriptor_Users_RefreshTokenUser = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Users/RefreshTokenUser',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.RefreshTokenUserRequest,
  proto.dating.erp.api.v1.services.LoginUserResponse,
  /**
   * @param {!proto.dating.erp.api.v1.services.RefreshTokenUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dating.erp.api.v1.services.LoginUserResponse.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.RefreshTokenUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.services.LoginUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.services.LoginUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.UsersClient.prototype.refreshTokenUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Users/RefreshTokenUser',
      request,
      metadata || {},
      methodDescriptor_Users_RefreshTokenUser,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.RefreshTokenUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.services.LoginUserResponse>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.UsersPromiseClient.prototype.refreshTokenUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Users/RefreshTokenUser',
      request,
      metadata || {},
      methodDescriptor_Users_RefreshTokenUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.GetOneTimeAccessRequest,
 *   !proto.dating.erp.api.v1.services.GetOneTimeAccessResponse>}
 */
const methodDescriptor_Users_GetOneTimeAccess = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Users/GetOneTimeAccess',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.GetOneTimeAccessRequest,
  proto.dating.erp.api.v1.services.GetOneTimeAccessResponse,
  /**
   * @param {!proto.dating.erp.api.v1.services.GetOneTimeAccessRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dating.erp.api.v1.services.GetOneTimeAccessResponse.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.GetOneTimeAccessRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.services.GetOneTimeAccessResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.services.GetOneTimeAccessResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.UsersClient.prototype.getOneTimeAccess =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Users/GetOneTimeAccess',
      request,
      metadata || {},
      methodDescriptor_Users_GetOneTimeAccess,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.GetOneTimeAccessRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.services.GetOneTimeAccessResponse>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.UsersPromiseClient.prototype.getOneTimeAccess =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Users/GetOneTimeAccess',
      request,
      metadata || {},
      methodDescriptor_Users_GetOneTimeAccess);
};


module.exports = proto.dating.erp.api.v1.services;

