import { ApiUrl, getMetadata } from '../index'
import {
  ListHistoryOperationsRequest
} from './history_operations_pb'
import { HistoryOperationsClient } from './history_operations_grpc_web_pb'

let client = new HistoryOperationsClient(ApiUrl, null, null);

const toHistoryOperationObject = (instance) => {
  const result = {}
  Object.keys(instance).forEach(key => {
    if (key.endsWith('List') || key.endsWith('Map') || key === "amount") {
      result[key] = instance[key]
    } else if (instance[key]?.value) {
      result[key] = instance[key]?.value
    } else {
      result[key] = instance[key]
    }
  })
  return result
}

const API = {
  listHistoryOperations: (params) => {
    const request = new ListHistoryOperationsRequest()
    if (params?.entityName) {
      request.setEntityName(params?.entityName ?? "")
    }

    return new Promise((resolve, reject) => {
      client.listHistoryOperations(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          const result = response.toObject()
          resolve({
            ...result,
            historyOperationsList: result.historyOperationsList.map(toHistoryOperationObject)
          })
        }
      })
    })
  }
};

export default API;
