import { ApiUrl, getMetadata } from '../index'
import {
  LoginUserRequest,
  RefreshTokenUserRequest,
  GetOneTimeAccessRequest
} from './users_pb'
import { UsersClient } from './users_grpc_web_pb'

let client = new UsersClient(ApiUrl, null, null)

const API = {
  login: ({ login, password }) => {
    const request = new LoginUserRequest()
    request.setLogin(login)
    request.setPassword(password)
    return new Promise((resolve, reject) => {
      client.loginUser(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      })
    })
  },
  refreshToken: ({ accessToken }) => {
    const request = new RefreshTokenUserRequest()
    request.setAccessToken(accessToken)
    return new Promise((resolve, reject) => {
      client.refreshToken(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      })
    })
  },
  getOneTimeAccess: () => {
    const request = new GetOneTimeAccessRequest()
    return new Promise((resolve, reject) => {
      client.getOneTimeAccess(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      })
    })
  }
}

export default API
