// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import API from 'api/customer_categories'

export const list = createAsyncThunk('appCustomerCategories/list', async () => {
  return await API.listCustomerCategories()
})

export const get = createAsyncThunk('appCustomerCategories/get', async name => {
  return await API.getCustomerCategory(name)
})

export const add = createAsyncThunk('appCustomerCategories/add', async (instance, { dispatch }) => {
  const updated = await API.applyCustomerCategory(instance)
  instance = {
    ...instance,
    ...updated
  }
  await dispatch(list())
  return instance
})

export const modify = createAsyncThunk('appCustomerCategories/modify', async (instance, { dispatch }) => {
  const updated = await API.applyCustomerCategory(instance)
  instance = {
    ...instance,
    ...updated
  }
  await dispatch(appCustomerCategoriesSlice.actions.setSelected(instance))
  await dispatch(list())
})

export const del = createAsyncThunk('appCustomerCategories/del', async (name, { dispatch }) => {
  await API.deleteCustomerCategory(name)
  await dispatch(list())
  return name
})

export const appCustomerCategoriesSlice = createSlice({
  name: 'appCustomerCategories',
  initialState: {
    data: []
  },
  reducers: {
    setSelected: (state, { payload }) => {
      state.selected = payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(list.fulfilled, (state, action) => {
        state.data = action.payload
      })
      .addCase(get.fulfilled, (state, { payload }) => {
        state.selected = payload
      })
  }
})

export default appCustomerCategoriesSlice.reducer
