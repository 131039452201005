// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ApiUrl, getFileUrl, getMetadata } from 'api'
import APIRooms from 'api/chat_rooms'
import APIMessages from 'api/chat_messages'

export const getUserProfile = createAsyncThunk('appChat/getUserProfile', async (_, { dispatch }) => {
  return {
    id: 'employees/*',
    avatar: require('assets/images/avatars/avatar-blank.png').default,
    displayName: 'Support',
    status: 'online',
    settings: { }
  }
})

export const getChatContacts = createAsyncThunk('appChat/getChatContacts', async () => {
  const current = {}

  let response = await APIRooms.listChatRooms({})
  const rooms = response?.roomsList ?? []

  return { 
    chatsContacts: rooms.map(r => {
      const user = r.usersList.filter(e => !e.isInternal)[0]
      const profile = r.usersList.filter(e => e.isInternal && e.name.startsWith("customers/"))[0]
      const idUser = !user ? '' :
        (user.name.startsWith("customers/") ? parseInt(user.name.replace("customers/", "")) : 
        user.name.replace("employees/", ""))
      const idProfile = profile?.name?.replace("customers/", "")
      return {
        id: [idUser, idProfile].filter(e => e).join("-"),
        displayName: user?.displayName ? user.displayName : r.displayName,
        avatar: user?.logoUrl ? getFileUrl(user?.logoUrl?.value) + '?h=100' : 
          require('assets/images/avatars/avatar-blank.png').default,
        isSystem: r.usersList.filter(e => e.name.startsWith("employees/")).length,
        profileDisplayName: profile?.displayName,
        // status: 'online',
        chat: { 
          id: r.id, 
          unseenMsgs: r.unreadMessages,
          lastMessage: null
        }
      }
    }), 
    contacts: rooms.map(r => {
      const user = r.usersList.filter(e => !e.isInternal)[0]
      return {
        id: !user ? "" : user.name.startsWith("customers/") ? parseInt(user.name.replace("customers/", "")) : 
          user.name.replace("employees/", ""),
        displayName: user?.displayName ? user.displayName : r.displayName,
        avatar: user?.logoUrl ? getFileUrl(user?.logoUrl?.value) + '?h=100' : 
          require('assets/images/avatars/avatar-blank.png').default,
        isSystem: r.usersList.filter(e => e.name.startsWith("employees/")).length
        // status: 'offline'
      }
    }),
    profileUser: {
      id: current?.id,
      avatar: current.logoUrl ? getFileUrl(current?.logoUrl?.value) + '?h=100' :
        require('assets/images/avatars/avatar-blank.png').default,
      displayName: current.displayName,
      status: 'online'
    } 
  }
})

export const selectChat = createAsyncThunk('appChat/selectChat', async (id, { dispatch, getState }) => {
  let response = await APIRooms.listChatRooms({})
  const rooms = response?.roomsList ?? []

  let room = null
  const ids = id.split("-")
  const userId = ids[0]
  const profileId = ids[1]
  if (profileId) {
    room = rooms.filter(r => r.usersList.filter(u => u.name === `customers/${userId}`).length && r.usersList.filter(u => u.name === `customers/${profileId}`).length)[0]
  } else {
    room = rooms.filter(r => r.usersList.filter(u => u.name === `customers/${userId}`).length && r.usersList.filter(u => u.name.startsWith("employees/")).length)[0]
  }

  response = await APIMessages.listChatMessages({
    roomName: room.name
  })
  const messages = response?.messagesList ?? []

  const user = room.usersList.filter(e => !e.isInternal)[0]

  // await dispatch(getChatContacts())
  return {
    chat: {
      chat: messages.map(e => ({
        ...e,
        message: e.text,
        time: e.createdAt,
        senderId: e.userName,
        isInternal: e.userName.startsWith("employees/") ? true : room.usersList.filter(u => u.name === e.userName)[0]?.isInternal
      })).reverse()
    },
    contact: {
      id,
      displayName: user.displayName,
      avatar: user?.logoUrl ? getFileUrl(user?.logoUrl?.value) + '?h=100' : 
        require('assets/images/avatars/avatar-blank.png').default
    }
  }
})

export const addChatByProfile = createAsyncThunk('appChat/addChatByProfile', async (profile, { dispatch }) => {
  await dispatch(appChatSlice.actions.addContact({
    id: profile.id,
    displayName: profile.username,
    avatar: getFileUrl(profile.selfieUrl) + "?h=100",
    status: profile.values?.Connected?.items[0]?.toLowerCase()
  }))
})

export const sendMsg = createAsyncThunk('appChat/sendMsg', async (obj, { dispatch, getState }) => {
  const roomName = obj.chat.chat[0].roomName

  const ids = obj.contact.id.split('-')
  const userName = ids.length > 1 ? `customers/${ids[1]}` : null

  const response = await APIMessages.applyChatMessage({
    roomName,
    userName,
    text: obj.message
  })
  
  await dispatch(selectChat(obj.contact.id))
  return { 
    newMessageData: {
      ...response.message,
      message: obj.message,
      time: response.message.createdAt,
      senderId: response.message.userName
    }, 
    id: obj.contact.id 
  }
})

export const appChatSlice = createSlice({
  name: 'appChat',
  initialState: {
    chats: [],
    contacts: [],
    supportChats: [],
    supportContacts: [],
    userProfile: {},
    selectedUser: {}
  },
  reducers: {
    addContact: (state, { payload }) => {
      if (!state.chats.map(c => c.id).includes(payload.id)) {
        state.contacts = [payload]
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.userProfile = action.payload
      })
      .addCase(getChatContacts.fulfilled, (state, action) => {
        const chats = action.payload.chatsContacts
        state.supportChats = chats.filter(e => e.isSystem)
        state.chats = chats.filter(e => !e.isSystem)
      })
      .addCase(selectChat.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default appChatSlice.reducer
