// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

import MessageAPI from 'api/email_messages'
import TopicAPI from 'api/email_topics'
import OwnerAPI from 'api/email_owners'

const folders = {
  "inbox": "INBOX",
  "sent": "INBOX.Sent",
  "draft": "INBOX.Drafts",
  "spam": "INBOX.Junk",
  "trash": "INBOX.Trash"
}

function convertKeyToValueMap(obj) {
  const valueMap = {};
  for (const [key, value] of Object.entries(obj)) {
      valueMap[value] = key;
  }
  return valueMap;
}

const folderValues = convertKeyToValueMap(folders)

export const getMailOwners = createAsyncThunk('appEmail/getMailOwners', async () => {
  const response = await OwnerAPI.listEmailOwners()
  return response.emailOwnersList.map(e => ({...e, foldersList: (e.foldersList ?? [])
    .map(f => ({ ...f, folder: folderValues[f.displayName] }))}))
})

export const getMailTopics = createAsyncThunk('appEmail/getMailTopics', async params => {
  const response = await TopicAPI.listEmailTopics({
    ...params,
    folderDisplayName: folders[params.folder],
    search: params.q,
    customerName: params.customer ? `customers/${params.customer}` : null
  })
  return {
    params,
    data: response.emailTopicsList,
    totalRows: response.totalRows
  }
})

export const getMails = createAsyncThunk('appEmail/getMails', async params => {
  const response = await MessageAPI.listEmailMessages({
    ...params,
    folderDisplayName: folders[params.folder],
    search: params.q,
    customerName: params.customer ? `customers/${params.customer}` : null
  })
  return {
    params,
    data: response.emailMessagesList,
    totalRows: response.totalRows
  }
})

export const sendMail = createAsyncThunk('appEmail/sendMail', async (data) => {
  const { name } = await MessageAPI.applyEmailMessage(data)
  return await MessageAPI.sendEmailMessage(name)
})

export const applyMailCustomer = createAsyncThunk('appEmail/applyMailCustomer', async ({ mail, customer }, { dispatch, getState }) => {
  const { emailTopicsList } = await TopicAPI.listEmailTopics({ threadIndex: mail.topicThreadIndex })
  if (!emailTopicsList.length)
    return
  const emailTopic = emailTopicsList[0]
  emailTopic.customerName = customer.name
  const stored = TopicAPI.applyEmailTopic(emailTopic)
  await dispatch(getMails(getState().email.params))
  return stored
})

export const updateMails = createAsyncThunk(
  'appEmail/updateMails',
  async ({ emailIds, dataToUpdate }, { dispatch, getState }) => {
    const mails = getState().email.mails
      .filter(e => emailIds.includes(e.id) && Object.keys(dataToUpdate).reduce((a, k) => a || e[k] !== dataToUpdate[k], false))
      .map(e => ({...e, ...dataToUpdate}))
    for (const mail of mails) {
      await MessageAPI.applyEmailMessage(mail)
    }
    await dispatch(getMailOwners())
    await dispatch(getMails(getState().email.params))
    return {
      emailIds,
      dataToUpdate,
      data: {}
    }
  }
)

export const updateMailLabel = createAsyncThunk(
  'appEmail/updateMailLabel',
  async ({ emailIds, label }, { dispatch, getState }) => {
    // TODO
    await dispatch(getMails(getState().email.params))
    return []
  }
)

export const paginateMail = createAsyncThunk('appEmail/paginateMail', async ({ dir, emailId }) => {
  const response = await axios.get('/apps/email/paginate-email', { params: { dir, emailId } })
  return response.data
})

export const selectCurrentMail = createAsyncThunk('appEmail/selectCurrentMail', async id => {
  const mail = await MessageAPI.getEmailMessage(`emailMessages/${id}`)
  if (mail.parentName) {
    const { emailMessagesList } = await MessageAPI.listEmailMessages({
      threadIndex: mail.topicThreadIndex
    })
    const replies = []
    let parentName = mail.parentName
    while (parentName) {
      const reply = emailMessagesList.filter(e => e.name === parentName)[0]
      if (!reply) {
        break
      }
      replies.push(reply)
      parentName = reply.parentName
    }
    mail.replies = replies
  }
  return mail
})

export const appEmailSlice = createSlice({
  name: 'appEmail',
  initialState: {
    mails: [],
    owners: [],
    params: {},
    emailsMeta: {},
    selectedMails: [],
    currentMail: null,
    topics: [],
    topicsParams: {}
  },
  reducers: {
    selectMail: (state, action) => {
      const selectedMails = state.selectedMails
      if (!selectedMails.includes(action.payload)) {
        selectedMails.push(action.payload)
      } else {
        selectedMails.splice(selectedMails.indexOf(action.payload), 1)
      }
      state.selectedMails = selectedMails
    },
    selectAllMail: (state, action) => {
      const selectAllMailsArr = []
      if (action.payload) {
        selectAllMailsArr.length = 0
        state.mails.forEach(mail => selectAllMailsArr.push(mail.id))
      } else {
        selectAllMailsArr.length = 0
      }
      state.selectedMails = selectAllMailsArr
    },
    resetSelectedMail: state => {
      state.selectedMails = []
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getMails.fulfilled, (state, action) => {
        let currMail = null
        if (state.currentMail !== null && state.currentMail !== undefined) {
          currMail = action.payload.data.find(i => i.id === state.currentMail.id)
        }
        state.currentMail = currMail
        state.params = action.payload.params
        state.mails = action.payload.data
        state.emailsMeta = {}
        state.total = action.payload.totalRows
      })
      .addCase(getMailOwners.fulfilled, (state, action) => {
        state.owners = action.payload
      })
      .addCase(getMailTopics.fulfilled, (state, action) => {
        state.topicsParams = action.payload.params
        state.topics = action.payload.data
        state.topicsTotal = action.payload.totalRows
      })
      .addCase(updateMails.fulfilled, (state, action) => {
        function updateMailData(email) {
          Object.assign(email, action.payload.dataToUpdate)
        }
        state.mails.forEach(email => {
          if (action.payload.emailIds.includes(email.id)) {
            updateMailData(email)
          }
        })
      })
      .addCase(paginateMail.fulfilled, (state, action) => {
        const data = action.payload
        const dataIndex = state.mails.findIndex(i => i.id === data.id)
        dataIndex === 0 ? (data.hasPreviousMail = false) : (data.hasPreviousMail = true)
        dataIndex === state.mails.length - 1 ? (data.hasNextMail = false) : (data.hasNextMail = true)
        state.currentMail = data
      })
      .addCase(selectCurrentMail.fulfilled, (state, action) => {
        state.currentMail = action.payload
      })
  }
})

export const { selectMail, selectAllMail, resetSelectedMail } = appEmailSlice.actions

export default appEmailSlice.reducer
