import { ApiUrl, getMetadata } from '../index'
import { Subscription } from './subscription_pb'
import {
  GetSubscriptionRequest,
  ListSubscriptionsRequest,
  ApplySubscriptionRequest,
  DeleteSubscriptionRequest
} from './subscriptions_pb'
import { SubscriptionsClient } from './subscriptions_grpc_web_pb'
import { Money } from '../money_pb'
import wrappers from 'google-protobuf/google/protobuf/wrappers_pb'

let client = new SubscriptionsClient(ApiUrl, null, null);

const toSubscriptionModel = (instance) => {
  let result = new Subscription()
  result.setName(instance.name)
  result.setDisplayName(instance.displayName)
  result.setProductName(instance.productName)
  result.setIsActive(instance.isActive)
  result.setDurationUnitCode(instance.durationUnitCode)
  result.setEarlyCancellationDays(instance.earlyCancellationDays)
  if (instance.tariff?.value) {
    const amt = new Money()
    amt.setValue(instance.tariff.value)
    amt.setCurrencyName(instance.tariff.currencyName)
    amt.setExponent(instance.tariff.exponent)
    result.setTariff(amt)
  }
  result.setRequiredCustomerPropertiesList(instance.requiredCustomerPropertiesList)

  return result
}

const API = {
  listSubscriptions: (params) => {
    const request = new ListSubscriptionsRequest()
    if (params?.page) {
      request.setPage(params.page)
    }
    if (params?.perPage) {
      request.setPerPage(params.perPage)
    }
    if (params?.sort) {
      request.setSort(params.sort)
    }
    if (params?.sortColumn) {
      request.setSortColumn(params.sortColumn)
    }
    if (params?.search) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.search)
      request.setSearch(wrapped)
    }
    if (params?.productName) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.productName)
      request.setProductName(wrapped)
    }
    return new Promise((resolve, reject) => {
      client.listSubscriptions(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  getSubscription: (name) => {
    const request = new GetSubscriptionRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.getSubscription(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  applySubscription: (instance) => {
    const applying = toSubscriptionModel(instance)
    const request = new ApplySubscriptionRequest();
    request.setSubscription(applying)
    return new Promise((resolve, reject) => {
      client.applySubscription(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      })
    })
  },
  deleteSubscription: (name) => {
    const request = new DeleteSubscriptionRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.deleteSubscription(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  }
};

export default API;
