/**
 * @fileoverview gRPC-Web generated client stub for dating.erp.api.v1.services
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: projects/erp/service/api/v1/services/customer_categories.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var projects_erp_service_api_v1_model_customer_category_pb = require('./customer_category_pb.js')
const proto = {};
proto.dating = {};
proto.dating.erp = {};
proto.dating.erp.api = {};
proto.dating.erp.api.v1 = {};
proto.dating.erp.api.v1.services = require('./customer_categories_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dating.erp.api.v1.services.CustomerCategoriesClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dating.erp.api.v1.services.CustomerCategoriesPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.ListCustomerCategoriesRequest,
 *   !proto.dating.erp.api.v1.services.ListCustomerCategoriesResponse>}
 */
const methodDescriptor_CustomerCategories_ListCustomerCategories = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.CustomerCategories/ListCustomerCategories',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.ListCustomerCategoriesRequest,
  proto.dating.erp.api.v1.services.ListCustomerCategoriesResponse,
  /**
   * @param {!proto.dating.erp.api.v1.services.ListCustomerCategoriesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dating.erp.api.v1.services.ListCustomerCategoriesResponse.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.ListCustomerCategoriesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.services.ListCustomerCategoriesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.services.ListCustomerCategoriesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.CustomerCategoriesClient.prototype.listCustomerCategories =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.CustomerCategories/ListCustomerCategories',
      request,
      metadata || {},
      methodDescriptor_CustomerCategories_ListCustomerCategories,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.ListCustomerCategoriesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.services.ListCustomerCategoriesResponse>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.CustomerCategoriesPromiseClient.prototype.listCustomerCategories =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.CustomerCategories/ListCustomerCategories',
      request,
      metadata || {},
      methodDescriptor_CustomerCategories_ListCustomerCategories);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.GetCustomerCategoryRequest,
 *   !proto.dating.erp.api.v1.model.CustomerCategory>}
 */
const methodDescriptor_CustomerCategories_GetCustomerCategory = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.CustomerCategories/GetCustomerCategory',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.GetCustomerCategoryRequest,
  projects_erp_service_api_v1_model_customer_category_pb.CustomerCategory,
  /**
   * @param {!proto.dating.erp.api.v1.services.GetCustomerCategoryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_customer_category_pb.CustomerCategory.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.GetCustomerCategoryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.CustomerCategory)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.CustomerCategory>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.CustomerCategoriesClient.prototype.getCustomerCategory =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.CustomerCategories/GetCustomerCategory',
      request,
      metadata || {},
      methodDescriptor_CustomerCategories_GetCustomerCategory,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.GetCustomerCategoryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.CustomerCategory>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.CustomerCategoriesPromiseClient.prototype.getCustomerCategory =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.CustomerCategories/GetCustomerCategory',
      request,
      metadata || {},
      methodDescriptor_CustomerCategories_GetCustomerCategory);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.ApplyCustomerCategoryRequest,
 *   !proto.dating.erp.api.v1.model.CustomerCategory>}
 */
const methodDescriptor_CustomerCategories_ApplyCustomerCategory = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.CustomerCategories/ApplyCustomerCategory',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.ApplyCustomerCategoryRequest,
  projects_erp_service_api_v1_model_customer_category_pb.CustomerCategory,
  /**
   * @param {!proto.dating.erp.api.v1.services.ApplyCustomerCategoryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_customer_category_pb.CustomerCategory.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.ApplyCustomerCategoryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.CustomerCategory)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.CustomerCategory>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.CustomerCategoriesClient.prototype.applyCustomerCategory =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.CustomerCategories/ApplyCustomerCategory',
      request,
      metadata || {},
      methodDescriptor_CustomerCategories_ApplyCustomerCategory,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.ApplyCustomerCategoryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.CustomerCategory>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.CustomerCategoriesPromiseClient.prototype.applyCustomerCategory =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.CustomerCategories/ApplyCustomerCategory',
      request,
      metadata || {},
      methodDescriptor_CustomerCategories_ApplyCustomerCategory);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.DeleteCustomerCategoryRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_CustomerCategories_DeleteCustomerCategory = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.CustomerCategories/DeleteCustomerCategory',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.DeleteCustomerCategoryRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.dating.erp.api.v1.services.DeleteCustomerCategoryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.DeleteCustomerCategoryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.CustomerCategoriesClient.prototype.deleteCustomerCategory =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.CustomerCategories/DeleteCustomerCategory',
      request,
      metadata || {},
      methodDescriptor_CustomerCategories_DeleteCustomerCategory,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.DeleteCustomerCategoryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.CustomerCategoriesPromiseClient.prototype.deleteCustomerCategory =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.CustomerCategories/DeleteCustomerCategory',
      request,
      metadata || {},
      methodDescriptor_CustomerCategories_DeleteCustomerCategory);
};


module.exports = proto.dating.erp.api.v1.services;

