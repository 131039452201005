/**
 * @fileoverview gRPC-Web generated client stub for dating.erp.api.v1.services
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: projects/erp/service/api/v1/services/messages.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var projects_erp_service_api_v1_model_message_pb = require('./message_pb.js')
const proto = {};
proto.dating = {};
proto.dating.erp = {};
proto.dating.erp.api = {};
proto.dating.erp.api.v1 = {};
proto.dating.erp.api.v1.services = require('./messages_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dating.erp.api.v1.services.MessagesClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dating.erp.api.v1.services.MessagesPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.ListMessagesRequest,
 *   !proto.dating.erp.api.v1.services.ListMessagesResponse>}
 */
const methodDescriptor_Messages_ListMessages = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Messages/ListMessages',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.ListMessagesRequest,
  proto.dating.erp.api.v1.services.ListMessagesResponse,
  /**
   * @param {!proto.dating.erp.api.v1.services.ListMessagesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dating.erp.api.v1.services.ListMessagesResponse.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.ListMessagesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.services.ListMessagesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.services.ListMessagesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.MessagesClient.prototype.listMessages =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Messages/ListMessages',
      request,
      metadata || {},
      methodDescriptor_Messages_ListMessages,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.ListMessagesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.services.ListMessagesResponse>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.MessagesPromiseClient.prototype.listMessages =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Messages/ListMessages',
      request,
      metadata || {},
      methodDescriptor_Messages_ListMessages);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.GetMessageRequest,
 *   !proto.dating.erp.api.v1.model.Message>}
 */
const methodDescriptor_Messages_GetMessage = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Messages/GetMessage',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.GetMessageRequest,
  projects_erp_service_api_v1_model_message_pb.Message,
  /**
   * @param {!proto.dating.erp.api.v1.services.GetMessageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_message_pb.Message.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.GetMessageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.Message)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.Message>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.MessagesClient.prototype.getMessage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Messages/GetMessage',
      request,
      metadata || {},
      methodDescriptor_Messages_GetMessage,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.GetMessageRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.Message>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.MessagesPromiseClient.prototype.getMessage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Messages/GetMessage',
      request,
      metadata || {},
      methodDescriptor_Messages_GetMessage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.ApplyMessageRequest,
 *   !proto.dating.erp.api.v1.model.Message>}
 */
const methodDescriptor_Messages_ApplyMessage = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Messages/ApplyMessage',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.ApplyMessageRequest,
  projects_erp_service_api_v1_model_message_pb.Message,
  /**
   * @param {!proto.dating.erp.api.v1.services.ApplyMessageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_message_pb.Message.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.ApplyMessageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.Message)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.Message>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.MessagesClient.prototype.applyMessage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Messages/ApplyMessage',
      request,
      metadata || {},
      methodDescriptor_Messages_ApplyMessage,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.ApplyMessageRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.Message>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.MessagesPromiseClient.prototype.applyMessage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Messages/ApplyMessage',
      request,
      metadata || {},
      methodDescriptor_Messages_ApplyMessage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.DeleteMessageRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Messages_DeleteMessage = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Messages/DeleteMessage',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.DeleteMessageRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.dating.erp.api.v1.services.DeleteMessageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.DeleteMessageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.MessagesClient.prototype.deleteMessage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Messages/DeleteMessage',
      request,
      metadata || {},
      methodDescriptor_Messages_DeleteMessage,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.DeleteMessageRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.MessagesPromiseClient.prototype.deleteMessage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Messages/DeleteMessage',
      request,
      metadata || {},
      methodDescriptor_Messages_DeleteMessage);
};


module.exports = proto.dating.erp.api.v1.services;

