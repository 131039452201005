// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import API from 'api/affilate_conversions'

export const list = createAsyncThunk('appAffilateConversions/list', async (params, { signal }) => {
  const response = await API.listAffilateConversions(params)
  if (signal.aborted)
    return
  return {
    params,
    data: response.affilateConversionsList,
    totalRows: response.totalRows
  }
})

export const appAffilateConversions = createSlice({
  name: 'appAffilateConversions',
  initialState: {
    data: [],
    total: 1,
    params: {}
  },
  reducers: {
    setSelected: (state, { payload }) => {
      state.selected = payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(list.fulfilled, (state, { payload }) => {
        state.data = payload.data
        state.params = payload.params
        state.total = payload.totalRows
      })
  }
})

export default appAffilateConversions.reducer
