/**
 * @fileoverview gRPC-Web generated client stub for dating.erp.api.v1.services
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: projects/erp/service/api/v1/services/collection_exports.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var projects_erp_service_api_v1_model_collection_export_pb = require('./collection_export_pb.js')
const proto = {};
proto.dating = {};
proto.dating.erp = {};
proto.dating.erp.api = {};
proto.dating.erp.api.v1 = {};
proto.dating.erp.api.v1.services = require('./collection_exports_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dating.erp.api.v1.services.CollectionExportsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dating.erp.api.v1.services.CollectionExportsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.ListCollectionExportsRequest,
 *   !proto.dating.erp.api.v1.services.ListCollectionExportsResponse>}
 */
const methodDescriptor_CollectionExports_ListCollectionExports = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.CollectionExports/ListCollectionExports',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.ListCollectionExportsRequest,
  proto.dating.erp.api.v1.services.ListCollectionExportsResponse,
  /**
   * @param {!proto.dating.erp.api.v1.services.ListCollectionExportsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dating.erp.api.v1.services.ListCollectionExportsResponse.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.ListCollectionExportsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.services.ListCollectionExportsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.services.ListCollectionExportsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.CollectionExportsClient.prototype.listCollectionExports =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.CollectionExports/ListCollectionExports',
      request,
      metadata || {},
      methodDescriptor_CollectionExports_ListCollectionExports,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.ListCollectionExportsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.services.ListCollectionExportsResponse>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.CollectionExportsPromiseClient.prototype.listCollectionExports =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.CollectionExports/ListCollectionExports',
      request,
      metadata || {},
      methodDescriptor_CollectionExports_ListCollectionExports);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.GetCollectionExportRequest,
 *   !proto.dating.erp.api.v1.model.CollectionExport>}
 */
const methodDescriptor_CollectionExports_GetCollectionExport = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.CollectionExports/GetCollectionExport',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.GetCollectionExportRequest,
  projects_erp_service_api_v1_model_collection_export_pb.CollectionExport,
  /**
   * @param {!proto.dating.erp.api.v1.services.GetCollectionExportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_collection_export_pb.CollectionExport.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.GetCollectionExportRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.CollectionExport)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.CollectionExport>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.CollectionExportsClient.prototype.getCollectionExport =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.CollectionExports/GetCollectionExport',
      request,
      metadata || {},
      methodDescriptor_CollectionExports_GetCollectionExport,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.GetCollectionExportRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.CollectionExport>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.CollectionExportsPromiseClient.prototype.getCollectionExport =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.CollectionExports/GetCollectionExport',
      request,
      metadata || {},
      methodDescriptor_CollectionExports_GetCollectionExport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.ApplyCollectionExportRequest,
 *   !proto.dating.erp.api.v1.model.CollectionExport>}
 */
const methodDescriptor_CollectionExports_ApplyCollectionExport = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.CollectionExports/ApplyCollectionExport',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.ApplyCollectionExportRequest,
  projects_erp_service_api_v1_model_collection_export_pb.CollectionExport,
  /**
   * @param {!proto.dating.erp.api.v1.services.ApplyCollectionExportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_collection_export_pb.CollectionExport.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.ApplyCollectionExportRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.CollectionExport)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.CollectionExport>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.CollectionExportsClient.prototype.applyCollectionExport =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.CollectionExports/ApplyCollectionExport',
      request,
      metadata || {},
      methodDescriptor_CollectionExports_ApplyCollectionExport,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.ApplyCollectionExportRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.CollectionExport>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.CollectionExportsPromiseClient.prototype.applyCollectionExport =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.CollectionExports/ApplyCollectionExport',
      request,
      metadata || {},
      methodDescriptor_CollectionExports_ApplyCollectionExport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.DeleteCollectionExportRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_CollectionExports_DeleteCollectionExport = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.CollectionExports/DeleteCollectionExport',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.DeleteCollectionExportRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.dating.erp.api.v1.services.DeleteCollectionExportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.DeleteCollectionExportRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.CollectionExportsClient.prototype.deleteCollectionExport =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.CollectionExports/DeleteCollectionExport',
      request,
      metadata || {},
      methodDescriptor_CollectionExports_DeleteCollectionExport,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.DeleteCollectionExportRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.CollectionExportsPromiseClient.prototype.deleteCollectionExport =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.CollectionExports/DeleteCollectionExport',
      request,
      metadata || {},
      methodDescriptor_CollectionExports_DeleteCollectionExport);
};


module.exports = proto.dating.erp.api.v1.services;

